import React from 'react';
import currencyFormat from './../utilities/CurrencyFormat';
import backgroundColor from './../utilities/BackgroundColor';
import percentFormat from './../utilities/PercentFormat';
import SelectedSecurityLink from './SelectSecurityLink';

class Holding extends React.Component {

    render() {

        const movementPct = this.props.holding.movementPct;
        const classList = backgroundColor(movementPct);

        return (
            <tr>
                <td><strong><SelectedSecurityLink security={this.props.holding} setSelectedSecurity={this.props.setSelectedSecurity} /></strong></td> 
                <td>{ this.props.holding.quantity }</td> 
                <td>{ currencyFormat(this.props.holding.purchasePrice, true) }</td> 
                <td>{ currencyFormat(this.props.holding.price, true) }</td>
                <td>{ currencyFormat((this.props.holding.purchasePrice * this.props.holding.quantity), true) }</td> 
                <td>{ currencyFormat(this.props.holding.quantity * this.props.holding.price, true)}</td>
                <td className={classList}><small>{ currencyFormat(this.props.holding.movementAmt, false) } ({ percentFormat(movementPct, true) })</small></td> 
                <td>{ this.props.holding.purchaseDate.toLocaleDateString() }</td>
                <td><button className="btn btn-success" onClick = { () => this.props.sellHolding(this.props.holding) }>Sell</button></td>
            </tr>
        )
    }
}

export default Holding