

const pluralizer = (word, num) => {
    let newWord = word;
    if(num > 1) {
        newWord += 's';
    }

    return newWord;
}

export default pluralizer