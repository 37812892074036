import { React } from 'react'
import SelectSecurityLink from './SelectSecurityLink';

const NewHeadlinesList = (props) => {

    let headline = props.headline;

    let selectSecurityLinks = [];

    let count = 0;
    const returnKey = () => {
        count++;
        return  'newHeadlineListKey' + count;
    }

    if(props.securities?.length > 0) {
        for(var k = 0; k < props.securities.length; k++) {
            selectSecurityLinks.push(<SelectSecurityLink setSelectedSecurity={props.setSelectedSecurity} security={props.securities[k]} key={returnKey()} />)
        }
    }

    
    // If there are securities with the message
    if(props.securities?.length > 0) {
    
        // Create a copy of the headline to operate on
        let headlineCopy = headline.slice(0);

        // Array of HTML parts to store from our headline string
        const htmlParts = [];

        // Loop through the securities
        props.securities.forEach((security, i) => {

            // Find the index of the security in the headline
            let securityIndex = headlineCopy.indexOf(security.symbol);

            // If it's not zero, the security appears after the beginning of the headline
            // Add the text from the beginning of the headline up to the found index into a span
            // and push it to our HTML parts array
            if(securityIndex !== 0) {
                const partHasColon = headlineCopy.indexOf(':') !== -1;
                const part = partHasColon ? <strong key={returnKey()}>{headlineCopy.slice(0, securityIndex)}</strong> : <span key={returnKey()}>{headlineCopy.slice(0, securityIndex)}</span>;

                htmlParts.push(part);
            }

            // Push the found security into the HTML parts array
            htmlParts.push(<SelectSecurityLink setSelectedSecurity={props.setSelectedSecurity} security={props.securities[i]} key={returnKey()} />)

            // Trim the stored parts of the headline from the copy before the loop restarts
            headlineCopy = headlineCopy.length > 3 ? headlineCopy.slice(securityIndex + 3) : headlineCopy;

        });

        // Add the final part of the headline into a span and push it to the HTML parts array
        htmlParts.push(<span key={returnKey()}>{headlineCopy}</span>);

        // Map each instance of the stored HTML parts array into an JSX object to display later
        const displayParts = htmlParts.map((part) => {
            return part
        });

        return (
            <li>{displayParts}</li>
        )
    } else {
        return (
            <li>{props.headline}</li>
        )
    }
}

export default NewHeadlinesList