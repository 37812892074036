import React from 'react';
import './App.css';
import Game from './components/Game';
import randomNumber from './utilities/RandomNumber';
import computeStockAverage from './utilities/ComputeStockAverage'
import headlines from './utilities/Headlines';
import getPeriodHigh from './utilities/GetPeriodHigh';
import getPeriodLow from './utilities/GetPeriodLow';
import twoDigitNumber from './utilities/TwoDigitNumber';

const startingBalance = 100000;


class App extends React.Component {
  constructor(props) {
    super(props);
    this.sellHolding = this.sellHolding.bind(this)
    this.sellAllShares = this.sellAllShares.bind(this)
    this.pauseGame = this.pauseGame.bind(this)
    this.unpauseGame = this.unpauseGame.bind(this)
    this.toggleShowHoldings = this.toggleShowHoldings.bind(this)
    this.nextTick = this.nextTick.bind(this)
    this.buyHolding = this.buyHolding.bind(this)
    this.setSelectedSecurity = this.setSelectedSecurity.bind(this)
    this.toggleShowPanel = this.toggleShowPanel.bind(this)
    this.toggleShowMarketDiversificationChart = this.toggleShowMarketDiversificationChart.bind(this)
    this.toggleShowNetWorthChanges = this.toggleShowNetWorthChanges.bind(this)
    this.toggleShowHoldingsChanges = this.toggleShowHoldingsChanges.bind(this)
    this.sortStocks = this.sortStocks.bind(this)
    this.skipDays = this.skipDays.bind(this)
    this.setTransactionModalState = this.setTransactionModalState.bind(this)
    this.addTransaction = this.addTransaction.bind(this)
    this.removeTransaction = this.removeTransaction.bind(this)

    this.state = {
        startingBalance: startingBalance,
        balance: startingBalance,
        createdStockNames: [],
        invested: 100,
        date: new Date(),
        openTransactionModal: false,
        transactionSecurity: {},
        openTransactionList: [],
        paused: true,
        secondsPerDay: 5,
        selectedSecurity: {},
        showHoldings: false,
        showPanel: false,
        showMarketDiversification: false,
        showNetWorthChanges: true,
        showHoldingsChanges: true,
        stockAverage: 300,
        startingStockAverage: 300,
        ticks: 0,
        stocksSortProp: '',
        stocksSortDirection: '',
        holdings: [
          {
            symbol: 'XYZ',
            price: 10,
            movementPct: 0,
            movementAmt: 0,
            quantity: 5,
            purchaseDate: new Date(),
            purchasePrice: 10
          }
        ],
        stocks: [
          {
            symbol: 'XYZ',
            price: 10,
            movementPct: 0,
            movementAmt: 0
          }
        ],
        funds: [
          {
            symbol: 'ALLX',
            price: 300,
            movementPct: 0,
            movementAmt: 0,
            yearlyHigh: 300,
            yearlyLow: 300
          }
        ],
        history: {
          stocks: [],
          funds: [],
          holdings: []
        },
        headlines: {
          topHeadline: 'Loading...',
          marketHeadlines: [],
          fiftyTwoWeekHiLo: [],
          otherHeadlines: []
        },
        bankruptcyLosses: {
          total: 0,
          valueTotal: 0,
          losses: []
        },
        prevTickValues: {
          netWorth: 100100,
          holdings: 100,
          stockAverage: 300,
          invested: 100
        }
    }
  }

  componentDidMount() {

    // Create Random Stocks

    let newStocks = [];
    let newStockSymbols = [];


    let newStockSymbol ='';
    const symbolIsUnique = (newSymbol) => newStockSymbols.indexOf(newSymbol) === -1;
    
    for (let i = 0; i < 30; i++ ) {

      newStockSymbol = this.getRandomStockName();

      while(!symbolIsUnique(newStockSymbol)) {
        newStockSymbol = this.getRandomStockName();
      }

      newStockSymbols.push(newStockSymbol);

      const stock = {
        symbol: newStockSymbol,
        price: 10,
        movementPct: 0,
        movementAmt: 0,
        yearlyHigh: 10,
        yearlyLow: 10
      }

      newStocks.push(stock);

    }

    let newHoldings = [];
    const firstRand = Math.floor(Math.random() * newStocks.length);
    let secondRand = Math.floor(Math.random() * newStocks.length);
    while(secondRand === firstRand) {
      secondRand = Math.floor(Math.random() * newStocks.length);
    }

    let firstRandomHolding = newStocks[firstRand];
    let secondRandomHolding = newStocks[secondRand];

    newHoldings.push({
      symbol: firstRandomHolding.symbol,
      price: 10,
      movementPct: 0,
      movementAmt: 0,
      quantity: 5,
      purchaseDate: new Date(),
      purchasePrice: 10
    }, {
      symbol: secondRandomHolding.symbol,
      price: 10,
      movementPct: 0,
      movementAmt: 0,
      quantity: 5,
      purchaseDate: new Date(),
      purchasePrice: 10
    })


    this.setState({
      stocks: newStocks,
      holdings: newHoldings,
      createdStockNames: newStockSymbols
    });


    this.skipDays(7);

  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  skipDays(num) {
    setTimeout(
      () => {
        for(let i = 0; i < num; i++) {
          this.tick();
        }
      },
      (10)
    );
  }

  pauseGame() {
    clearInterval(this.timerID);
    this.setState({
      paused: true
    });
  }

  unpauseGame() {
    this.timerID = setInterval(
      () => this.tick(),
      (this.state.secondsPerDay * 125)
    );

    this.setState({
      paused: false
    });
  }

  setSelectedSecurity(security) {
    // console.log('setSelectedSecurity Fired');
    // console.log(security);

    this.setState({
      selectedSecurity: security,
      showHoldings: true,
      showPanel: true
    })
  }

  getRandomStockName = () => {

    const generateStockSymbol = () => {
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let charactersLength = characters.length;
      let symbol = '';
      for ( let k = 0; k < 3; k++ ) {
        symbol += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return symbol;

    }
    
    let createdStocksNamesArr = this.state.createdStockNames.slice(0);

    let randomStock = generateStockSymbol();

    let stockIsUnique = createdStocksNamesArr.indexOf(randomStock) === -1;

    while (!stockIsUnique) {
      randomStock = generateStockSymbol();
      stockIsUnique = createdStocksNamesArr.indexOf(randomStock) === -1;
    }
    
    this.addCreatedStock(randomStock);

    return randomStock;
  }

  addCreatedStock(stockSymbol) {

    let oldCreatedStockNames = this.state.createdStockNames.slice(0);
    oldCreatedStockNames.push(stockSymbol);
    this.setState({
      createdStockNames: oldCreatedStockNames
    });
  }

  toggleShowHoldingsChanges() {
    let newShowHoldingsChanges = !this.state.showHoldingsChanges;
    this.setState({
      showHoldingsChanges: newShowHoldingsChanges
    })
  }

  toggleShowNetWorthChanges() {
    let newShowNetWorthChanges = !this.state.showNetWorthChanges;
    this.setState({
      showNetWorthChanges: newShowNetWorthChanges
    })
  }

  toggleShowMarketDiversificationChart() {
    let newShowMarketDiversification = !this.state.showMarketDiversification;
    this.setState({
      showMarketDiversification: newShowMarketDiversification
    })
  }

  toggleShowPanel() {

    let newSelectedSecurity = this.state.selectedSecurity;
    if(this.state.showPanel) {
      newSelectedSecurity = {};
    }

    this.setState({
      showPanel: !this.state.showPanel,
      selectedSecurity: newSelectedSecurity
    })
  }

  toggleShowHoldings() {
      
    let newSelectedSecurity = this.state.selectedSecurity;
    let newShowPanel = this.state.showPanel;
    if(this.state.showHoldings) {
      newSelectedSecurity = {};
    }

    if(!this.state.showHoldings) {
      newShowPanel = false;
    }

    console.log(newSelectedSecurity);
    this.setState({
      showHoldings: !this.state.showHoldings,
      selectedSecurity: newSelectedSecurity,
      showPanel: newShowPanel
    })

  }

  nextTick() {
    this.tick();
  }

  tick() {

    const randomStockMovement = (prevPrice) => {
      // Sets the maximum amount of volatility based on chance
      // 65% chance of up to 3% price movement
      // 35% chance of up to 5% price movement
      // 15% chance of up to 8% price movement
      // 5% chance of up to 12% price movement
      const seed = Math.random();
      let max = 0;
      const isPennyStock = prevPrice < 7;
      const baseDivisor = 50;
      // want number to be 2 when price is 5
      const pennyStockFactor = (baseDivisor / prevPrice);
      let maxPercentBase  = 0;

      if(seed > 0.95) {
          maxPercentBase = 12;
       } else if (seed > 0.85) {
          maxPercentBase = 8;
       } else if (seed > 0.65) {
        maxPercentBase = 5;
       } else {
        maxPercentBase = 3;
      }

      max = isPennyStock ?  pennyStockFactor * maxPercentBase : maxPercentBase;

      return randomNumber(max);
    }

    const prevStocks = this.state.stocks;
    const stocksHistory = this.state.history.stocks;
    const fundsHistory = this.state.history.funds;

    const bullVar = Math.random();
    const bearVar = Math.random();
    const bullDay = bullVar > 0.8 && (bullVar > bearVar);
    const bearDay = bearVar > 0.8 && (bearVar > bullVar);

    const updateStockPrice = (stock) => {
      const prevPrice = stock.price;

      const upOrDown = Math.random() >= 0.5;
      let upOrDownPct = upOrDown ? randomStockMovement(prevPrice) : 0 - randomStockMovement(prevPrice);
      upOrDownPct = bullDay ? Math.round(upOrDownPct + Math.max(randomNumber(2), randomNumber(4)) * 100) / 100 : upOrDownPct;
      upOrDownPct = bearDay ? Math.round(upOrDownPct - Math.max(randomNumber(2), randomNumber(4)) * 100) / 100 : upOrDownPct;
      const swingDay = bearDay || bullDay ? Math.random() < 0.25 : Math.random() > 0.90;

      upOrDownPct = swingDay ? upOrDownPct * 2 : upOrDownPct;
      if(upOrDownPct < -100) {
        upOrDownPct = -100;
      }

      const extraordinaryGain = bullDay && swingDay && Math.random() < 0.2;
      const extraGainAmt = Math.max(randomNumber(50), randomNumber(65));
      const extraordinaryLoss = bearDay && swingDay && Math.random() < 0.2;
      const extraLossAmt = 0 - Math.max(randomNumber(50), randomNumber(65));

      upOrDownPct = extraordinaryGain ? extraGainAmt : upOrDownPct;
      upOrDownPct = extraordinaryLoss && !extraordinaryGain ? extraLossAmt : upOrDownPct;

      let priceChange = prevPrice * (upOrDownPct / 100);
      priceChange = + priceChange.toFixed(2);
      let newStockPrice = twoDigitNumber(Math.max(prevPrice + priceChange, 0));
      // Each stock's 52 week, 30 and 90 day highs can be set here
      let yearlyHighValue = getPeriodHigh(stock, stocksHistory, prevStocks, newStockPrice, 365);
      let yearlyLowValue = getPeriodLow(stock, stocksHistory, prevStocks, newStockPrice, 365);

      // Merge - Company is worth less than a small % of the average + 33% chance
        // Holding is converted to some fraction of shares of an existing company, existing company moves
        // Stock is replaced by a new company at 3.33% of the average per share
      // Split - Company is worth more than a 33% of the average + 33% chance
        // Company being worth more than 33% of average triggers market analyst headline
        // Holding values change for quantity, share price, purchase price
        // Stock price declines according to the ratio of the split
        // Split ratio randomly determined, but bounded.
        // Can be 2:1 or 3:2

      // Each should generate a headlines:
      // Big company buys Small company in all stock deal
      // New company debuts etc
      // Big company stock splits (ratio)


      // Bankruptcy logic
      // Replace bankrupt company on the exchange if...
      // Price is less than $5 per share (25% chance)
      // All other companies (0.15% chance)
      // Company is "too big to fail" (dynamic odds)

      // If company was "too big to fail" it has a higher likelihood of going bankrupt
      // Bigger than 20% of the market? 1% chance of failure (@ 20% of size, goes up as size goes up)
      // At 10% of the market, company has a 1/365 chance of going bankrupt, roughly twice the normal odds
      // At 20% of market weight, company has a 2/365 chance, roughly four times normal odds

      // Default is 1/(365 * 2) chance of bankruptcy
      const defaultBankruptcyOdds = 0.000913;

      // Double the default odds for companies that are more than 10% of the whole market
      const tooBigTooFailDivisor = ((prevPrice / computeStockAverage(prevStocks)) / 2) / defaultBankruptcyOdds;
      let bankruptcyRiskBasedOnPrice = (prevPrice / computeStockAverage(prevStocks)) / tooBigTooFailDivisor;

      // Bankruptcy odds calculation
      let bankruptcyFactor = prevPrice / computeStockAverage(prevStocks) > 0.10 ? bankruptcyRiskBasedOnPrice : defaultBankruptcyOdds;

      if((newStockPrice < 5 && Math.random() > 0.75) || (Math.random() < bankruptcyFactor)) {
        newStockPrice = 0;
        upOrDownPct = -1;
        priceChange = 0 - prevPrice;
        yearlyLowValue = 0;
      }

      const container = {
        symbol: stock.symbol,
        price: newStockPrice,
        movementPct: upOrDownPct,
        movementAmt: priceChange,
        yearlyHigh: yearlyHighValue,
        yearlyLow: yearlyLowValue
      }
      return container;

    };
    let newStocks = prevStocks.map(updateStockPrice);

    const hasBankruptcy = newStocks.findIndex((stock) =>
      stock.price === 0
    ) !== -1;

    if(hasBankruptcy) {
      for(let b = 0; b <= newStocks.length - 1; b++) {
        if(newStocks[b].price === 0) {
          const newPrice = twoDigitNumber(Math.max(computeStockAverage(newStocks) / newStocks.length, 10));
          newStocks.push({
            symbol: this.getRandomStockName(),
            price: newPrice,
            movementPct: 0,
            movementAmt: 0,
            yearlyHigh: newPrice,
            yearlyLow: newPrice
          })
        }
      }
    }

    const newStocksSorted = newStocks.sort(function (a, b) {
      return b.movementPct - a.movementPct;
    });

    const newStocksFiltered = newStocksSorted.filter((stock) => 
      stock.price !== 0
    );

    const newStockAverage = computeStockAverage(newStocksFiltered);

    const prevHoldings = this.state.holdings;

    let initialValue = 0;
    const prevHoldingTotal = prevHoldings.reduce((accumulator, currentValue) => accumulator + (currentValue.price * currentValue.quantity), initialValue);

    const indexFundYearlyHigh = getPeriodHigh(this.state.funds[(this.state.funds.length - 1)], this.state.history.funds, this.state.funds, newStockAverage, 365);
    const indexFundYearlyLow = getPeriodLow(this.state.funds[(this.state.funds.length - 1)], this.state.history.funds, this.state.funds, newStockAverage, 365);

    const updatedFundsArr = [{
      symbol: 'ALLX',
      price: newStockAverage,
      movementAmt: newStockAverage - this.state.stockAverage,
      movementPct: (100 * (newStockAverage - this.state.stockAverage) / this.state.stockAverage),
      yearlyHigh: indexFundYearlyHigh,
      yearlyLow: indexFundYearlyLow
    }];

    const updatePercent = (newPrice, purchasePrice) => {
        return Number((((newPrice - purchasePrice) / purchasePrice) * 100).toFixed(2));
    }

    const updateHoldingPrice = (holding) => {

      for(let i = 0; i < newStocks.length; i++) {
        if(newStocks[i].symbol === holding.symbol) {
          const container = {
            symbol: holding.symbol,
            price: newStocks[i].price,
            movementAmt: (newStocks[i].price - holding.purchasePrice) * holding.quantity,
            movementPct: updatePercent(newStocks[i].price, holding.purchasePrice),
            quantity: holding.quantity,
            purchaseDate: holding.purchaseDate,
            purchasePrice: holding.purchasePrice
          }

          return container;
        }
      }

      for(let k = 0; k < updatedFundsArr.length; k++) {
        if(updatedFundsArr[k].symbol === holding.symbol) {
          const container = {
            symbol: holding.symbol,
            price: newStockAverage,
            movementAmt: (newStockAverage - holding.purchasePrice) * holding.quantity,
            movementPct: updatePercent(newStockAverage, holding.purchasePrice),
            quantity: holding.quantity,
            purchaseDate: holding.purchaseDate,
            purchasePrice: holding.purchasePrice
          }

          return container;
        }
      }

      return null;
    }

    const newHoldings = prevHoldings.map(updateHoldingPrice);
    const newHoldingsSorted = newHoldings.sort(function (a, b) {
      return b.movementPct - a.movementPct;
    });

    let bankruptcyLossesObj = {
      ...this.state.bankruptcyLosses
    };
    const bankruptHoldings = newHoldingsSorted.filter((holding) =>
      holding.price === 0
    );

    bankruptHoldings.forEach((holding) => {

      const filterHoldingsBySymbol = bankruptHoldings.filter((bankruptHolding) => {
        return bankruptHolding.symbol === holding.symbol
      });

      const bankruptcyLossesTotalThisHolding = filterHoldingsBySymbol.reduce(
        (accum,item) => accum + (item.purchasePrice * item.quantity), 0
      );

      let stocksYesterday = this.state.stocks.slice(0);
      let stocksYesterdayFiltered = stocksYesterday.filter((stock) => {
        return stock.symbol === holding.symbol;
      });
      let stockYesterday = stocksYesterdayFiltered[0];

      const bankruptcyPriceTotalThisHolding = filterHoldingsBySymbol.reduce(
        (accum,item) => accum + (Math.abs(stockYesterday.price) * item.quantity), 0
      );

      const bankruptcyQuantityTotalThisHolding = filterHoldingsBySymbol.reduce(
        (accum,item) => accum + item.quantity, 0
      );
      
      let today = new Date();
      today.setDate(today.getDate() + this.state.ticks);

      const newLoss = {
        stock: holding.symbol,
        date: today,
        quantity: bankruptcyQuantityTotalThisHolding,
        totalLost: bankruptcyLossesTotalThisHolding,
        valueAtTimeOfLoss: bankruptcyPriceTotalThisHolding
      }

      let stockAlreadyInLossesArr = bankruptcyLossesObj.losses.filter((holding) => {
        return holding.stock === newLoss.stock;
      });
      if(stockAlreadyInLossesArr.length === 0) {
        bankruptcyLossesObj.losses.push(newLoss);
        bankruptcyLossesObj.total = bankruptcyLossesObj.total + bankruptcyLossesTotalThisHolding;
        bankruptcyLossesObj.valueTotal = bankruptcyLossesObj.valueTotal + bankruptcyPriceTotalThisHolding;
      }

    });

    const newHoldingsFiltered = newHoldingsSorted.filter((holding) =>
      holding.price !== 0
    );

    const headlinesObj = headlines({
      stockAverage: newStockAverage,
      prevStockAverage: this.state.stockAverage,
      bullDay: bullDay,
      bearDay: bearDay,
      stocks: newStocksSorted,
      funds: updatedFundsArr,
      ticks: this.state.ticks,
      startingStockAverage: this.state.startingStockAverage,
      fundsHistory: fundsHistory
    });

    const newTicks = this.state.ticks + 1;
    let tomorrow = new Date();
    const today = new Date();
    tomorrow.setDate(tomorrow.getDate() + this.state.ticks + 1 - 7);
    today.setDate(today.getDate() + this.state.ticks - 7);

    let oldStockHistory = this.state.history.stocks.slice(0);
    let newStockHistory = oldStockHistory;

    const historyObj = {
      date: today,
      stocks: this.state.stocks
    };

    if(oldStockHistory.length !== 0) {
      newStockHistory.push(historyObj)
    } else {
      newStockHistory = [historyObj];
    }

    let oldFundsHistory = this.state.history.funds.slice(0);
    let newFundsHistory = oldFundsHistory;

    const fundsHistoryObj = {
      date: today,
      funds: this.state.funds
    };

    if(oldFundsHistory.length !== 0) {
      newFundsHistory.push(fundsHistoryObj)
    } else {
      newFundsHistory = [fundsHistoryObj];
    }

    let oldHoldingsHistory = this.state.history.holdings.slice(0);
    let newHoldingsHistory = oldHoldingsHistory;
    const holdingsTotal = this.state.holdings.reduce((accumulator, currentValue) => accumulator + (currentValue.price * currentValue.quantity), initialValue);
    const holdingsHistoryObj = {
      date: today,
      holdings: this.state.holdings,
      total: (this.state.balance + holdingsTotal)
    };
    
    if(oldHoldingsHistory.length !== 0) {
      newHoldingsHistory.push(holdingsHistoryObj);
    } else {
      newHoldingsHistory = [holdingsHistoryObj];
    }

    this.setState({
      date: tomorrow,
      stocks: newStocksFiltered,
      holdings: newHoldingsFiltered,
      funds: updatedFundsArr,
      bankruptcyLosses: bankruptcyLossesObj,
      headlines: {
        topHeadline: headlinesObj.mainHeadlineText,
        marketHeadlines: headlinesObj.marketHeadlinesList,
        otherHeadlines: headlinesObj.otherHeadlinesList,
        fiftyTwoWeekHiLo: headlinesObj.fiftyTwoWeekHiLo
      },
      stockAverage: newStockAverage,
      ticks: newTicks,
      prevTickValues: {
        holdings: prevHoldingTotal,
        netWorth: prevHoldingTotal + this.state.balance,
        stockAverage: this.state.stockAverage,
        invested: this.state.invested
      },
      history: {
        stocks: newStockHistory,
        holdings: newHoldingsHistory,
        funds: newFundsHistory
      }
    });

  }

  buyHolding = (quantity, holding) => {
    console.log('Buy Stock');
    console.log(holding);
    const newHolding = holding;
    holding.quantity = quantity;
    const newHoldings = this.state.holdings.slice(0);
    newHoldings.push(holding);
    const newHoldingsSorted = newHoldings.sort(function (a, b) {
      return b.movementPct - a.movementPct;
    });

    this.setState({
      balance: this.state.balance - (quantity * newHolding.price),
      invested: this.state.invested + (quantity * newHolding.price),
      holdings: newHoldingsSorted,
      openTransactionList: [],
      openTransactionModal: false
    })
  }

  sortStocks = (sortByProp) => {

    const currentSortProp = this.state.stocksSortProp;
    const currentSortDirection = this.state.stocksSortDirection;

    let stocks = this.state.stocks.slice(0);
    let newSortDirection = '';
    if(currentSortProp === sortByProp) {
      switch(currentSortDirection) {
        case 'asc':
          newSortDirection = 'desc';
          break;
        case 'desc':
          newSortDirection = '';
          break;
        default:
          newSortDirection = 'asc';
      }
    } else {
      newSortDirection = 'asc';
    }


    const newStocksSorted = stocks.sort(function (a, b) {

      if(!isNaN(a[sortByProp])) {
        if(newSortDirection === 'asc') {
          return b[sortByProp] - a[sortByProp];
        }

        if(newSortDirection === 'desc') {
          return a[sortByProp] - b[sortByProp];
        }

        if(newSortDirection === '') {
          return b.movementPct - a.movementPct;
        }
      }

    });

    this.setState({
      stocksSortProp: sortByProp,
      stocksSortDirection: newSortDirection,
      stocks: newStocksSorted
    });
  }

  sellAllShares = (holding) => {
    console.log('Sell All Shares: Holding');
    console.log(holding);

    const prevHoldings = this.state.holdings;
    const newHoldings = prevHoldings.filter(function(item) {
      const symbolMatch = item.symbol === holding.symbol;
      return !symbolMatch;
    });

    const matchHoldings = prevHoldings.filter(function(item) {
      const symbolMatch = item.symbol === holding.symbol;
      return symbolMatch;
    });

    let initialValue = 0;
    const salesTotal = matchHoldings.reduce((accumulator, currentValue) => accumulator + (currentValue.price * currentValue.quantity), initialValue);
    const investedTotal = matchHoldings.reduce((accumulator, currentValue) => accumulator + (currentValue.purchasePrice * currentValue.quantity), initialValue);

    this.setState({
      balance: this.state.balance + salesTotal,
      invested: this.state.invested - investedTotal,
      holdings: newHoldings
    })
  };

  sellHolding = (holding) => {
    console.log('Sell Holding');
    console.log(holding);

    const prevHoldings = this.state.holdings;
    const newHoldings = prevHoldings.filter(function(item) {

      return item !== holding;

    });

    this.setState({
      balance: this.state.balance + (holding.price * holding.quantity),
      invested: this.state.invested - (holding.purchasePrice * holding.quantity),
      holdings: newHoldings
    })
  }

  setTransactionModalState = (state, security) => {

    if(state) {
      this.setState({
        openTransactionModal: state,
        transactionSecurity: security
      });
    } else {
      this.setState({
        openTransactionModal: false,
        openTransactionList: [],
        transactionSecurity: security
      });
    }

  }

  removeTransaction = (transactionIndex) => {
    let newTransactionList = this.state.openTransactionList.slice(0);

    newTransactionList.splice(transactionIndex, 1);
    
    this.setState({
      openTransactionList: newTransactionList
    });

  }

  addTransaction = (transaction) => {

    let newTransactionList = this.state.openTransactionList.slice(0);

    newTransactionList.push(transaction);
    
    this.setState({
      openTransactionList: newTransactionList
    });

  }

  render() {
      return (
      <div className="App">
        <Game date={ this.state.date }
              stocks={ this.state.stocks }
              funds = {this.state.funds }
              holdings={ this.state.holdings }
              sellHolding={ this.sellHolding }
              sellAllShares = {this.sellAllShares }
              buyHolding={this.buyHolding }
              balance= { this.state.balance }
              invested = { this.state.invested }
              startingBalance = { this.state.startingBalance }
              headlines = { this.state.headlines }
              prevTickValues = { this.state.prevTickValues }
              pauseGame = { this.pauseGame }
              unpauseGame = { this.unpauseGame }
              paused = { this.state.paused }
              stockAverage = { this.state.stockAverage }
              toggleShowHoldings = { this.toggleShowHoldings }
              toggleShowPanel = { this.toggleShowPanel }
              showHoldings = { this.state.showHoldings }
              showPanel = {this.state.showPanel }
              showNetWorthChanges = { this.state.showNetWorthChanges }
              toggleShowNetWorthChanges = { this.toggleShowNetWorthChanges }
              showHoldingsChanges = { this.state.showHoldingsChanges }
              toggleShowHoldingsChanges = { this.toggleShowHoldingsChanges }
              showMarketDiversification = {this.state.showMarketDiversification}
              toggleShowMarketDiversificationChart = { this.toggleShowMarketDiversificationChart }
              nextTick = {this.nextTick }
              startingStockAverage =  { this.state.startingStockAverage }
              selectedSecurity = { this.state.selectedSecurity }
              setSelectedSecurity = { this.setSelectedSecurity }
              history = { this.state.history }
              ticks={this.state.ticks}
              bankruptcyLosses = {this.state.bankruptcyLosses}
              sortStocks = {this.sortStocks }
              skipDays={this.skipDays}
              openTransactionModal={ this.state.openTransactionModal }
              setTransactionModalState = { this.setTransactionModalState }
              transactionSecurity = {this.state.transactionSecurity}
              openTransactionList = {this.state.openTransactionList}
              addTransaction = { this.addTransaction }
              removeTransaction = { this.removeTransaction }
        />
      </div>
    );
  }
}

export default App;
