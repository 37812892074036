import { React } from 'react'
import ToggleHoldingsButton from './ToggleHoldingsButton'
import TogglePanelButton from './TogglePanelButton'
import HoldingsTable from './HoldingsTable'
import SelectedSecurity from './SelectedSecurity'
import BankruptHoldings from './BankruptHoldings'
import PorfolioDiversificationChart from './PortfolioDiversificationChart'
import currencyFormat from '../utilities/CurrencyFormat'
import NextDayButton from './NextDayButton'
import PortfolioPerformanceChart from './PortfolioPerformanceChart'
import SellAllButton from './SellAllButton';
import percentFormat from '../utilities/PercentFormat'

function Holdings(props) {

    const filterBySecurity = Object.keys(props.selectedSecurity).length === 0 && props.selectedSecurity?.constructor === Object;
    
    const selectedSecuritySymbol = !filterBySecurity ? props.selectedSecurity?.symbol : '';
    const isStock = props.stocks.findIndex((element) => element.symbol === props.selectedSecurity?.symbol) !== -1;
    let securitiesArr = isStock ? props.stocks.slice(0) : props.funds.slice(0);

    if(!filterBySecurity) {
        securitiesArr = securitiesArr.filter((security) => 
            security.symbol === selectedSecuritySymbol
        )
    }

    // Abstract contents below into components
    // 1. Panel buttons - Receives whether filtering by security (boolean) and whether viewing bankrupt holdings (new boolean)
    //          - Displays: toggleHoldings button when in filtered view or bankrupt holdings view
    //          - Displays: panel toggle all the time  
    // 2. Body section - Receives filter by security boolean and view bankrupt holdings boolean
    //          - Displays: Selected Security when selected security is true boolean
    //          - Displays: Bankrupt Holdings ONLY when selected
    //          - Displays: Holdings Table only when Bankrupt Holdings is NOT selected
    const contents = !filterBySecurity && securitiesArr.length ? <div className="flex flex-col side-panel">
            <div className="panel-buttons">
                <TogglePanelButton showPanel={props.showPanel} toggleShowPanel={props.toggleShowPanel} />
                <NextDayButton nextTick={props.nextTick} showPanel={props.showPanel} />
                <ToggleHoldingsButton showHoldings={ props.showHoldings } toggleShowHoldings={props.toggleShowHoldings} />
            </div>
            <SelectedSecurity
                selectedSecurity={securitiesArr[0]}
                stocks={props.stocks}
                holdings={props.holdings}
                holdingsValue={props.holdingsValue}
                history={props.history}
                ticks={props.ticks}
                stockAverage={props.stockAverage}
                balance={props.balance}
                date={props.date}
                buyHolding={props.buyHolding}
                sellAllShares={props.sellAllShares} />
            <HoldingsTable holdings={props.holdings} selectedSecurity={props.selectedSecurity} setSelectedSecurity={props.setSelectedSecurity} sellHolding={props.sellHolding}  balance={props.balance} />
        </div> :
            <div className="flex flex-col side-panel">
                <div className="panel-buttons">
                    <TogglePanelButton showPanel={props.showPanel} toggleShowPanel={props.toggleShowPanel} />
                    <NextDayButton nextTick={props.nextTick} showPanel={props.showPanel} />
                </div>
                <h1 className="portfolio-title mt-0 mb-0">
                    <small className="block label">Portfolio</small>
                    {currencyFormat(props.balance + props.holdingsValue, true)}
                </h1>
                <div className="w100">
                    <div className="flex portfolio-movement">
                        <h3 className="table-row">
                            <strong className="block">Today</strong>
                            <span>
                                {currencyFormat(props.amount - props.prevBalance, false)} ({percentFormat(Math.round((props.amount - props.prevBalance) / props.prevBalance * 100 * 100) / 100, false) })
                            </span>
                        </h3>
                        <h3 className="table-row">
                            <strong className="block">Total</strong>
                            <span>
                                {currencyFormat(props.amount - props.startingBalance, false)} ({percentFormat(Math.round((props.amount - props.startingBalance) / props.startingBalance * 100 * 100) / 100 , false) })
                            </span>
                        </h3>
                    </div>
                </div>
                <PortfolioPerformanceChart history={props.history.holdings} ticks={props.ticks} balance={props.balance} holdingsValue={props.holdingsValue} />
                <PorfolioDiversificationChart holdings={props.holdings} balance={props.balance} holdingsValue={props.holdingsValue} />
                <HoldingsTable holdings={props.holdings} selectedSecurity={props.selectedSecurity}  setSelectedSecurity={props.setSelectedSecurity} sellHolding={props.sellHolding}  balance={props.balance} />
                <BankruptHoldings bankruptcyLosses={props.bankruptcyLosses} />
            </div>;

    if(props.showPanel) {
        return (
            <div className="flex-col holdings-col open">
                {contents}
            </div>
        )
    } else {
        return (
            <div className="flex-col holdings-col closed">
                {contents}
            </div>
        )
    }

}

export default Holdings