

const backgroundColor = (num) => {
    let classList = '';
    if(num > 5) {
        classList += 'dark-green';
    } else if (num > 0) {
        classList += 'light-green';
    } else if ( num < -5) {
        classList += 'dark-red';
    } else if ( num < 0) {
        classList += 'light-red';
    } else {
        classList = '';
    }

    return classList;
}

export default backgroundColor