const getRandomStock = (stocksArr) => {

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    return stocksArr[getRandomInt(0, stocksArr.length - 1)];

}

export default getRandomStock;