import { React } from 'react'

import computeStockAverage from '../utilities/ComputeStockAverage'
import percentFormat from '../utilities/PercentFormat'
import twoDigitNumber from '../utilities/TwoDigitNumber'
import { Pie } from 'react-chartjs-2';

function StockMarketPieChart(props) {

    const stocksArr = props.stocks.slice(0);

    let data = [];
    let labels= [];
    const average = computeStockAverage(stocksArr);

    for (let i = 0; i < stocksArr.length; i++ ) {
        data.push(twoDigitNumber((stocksArr[i].price/average) * 100));
        labels.push(stocksArr[i].symbol);
    }

    // Can render price on the data label

    const optionsData = {
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
               label: function(tooltipItem, data) {
                      return data.labels[tooltipItem.index] + ' Market Weight: ' + percentFormat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], true);
               }
            }
        }
    };

    const pieData = {
        labels: labels,
        datasets: [{
            data: data,
            backgroundColor: [
                '#B71C1C',
                '#880E4F',
                '#4A148C',
                '#673AB7',
                '#311B92',
                '#3F51B5',
                '#1A237E',
                '#2196F3',
                '#0D47A1',
                '#81D4FA',
                '#84FFFF',
                '#00BCD4',
                '#006064',
                '#A7FFEB',
                '#009688',
                '#004D40',
                '#4CAF50',
                '#1B5E20',
                '#00C853',
                '#8BC34A',
                '#33691E',
                '#CDDC39',
                '#827717',
                '#FFEB3B',
                '#C6FF00',
                '#FF9800',
                '#FF6F00',
                '#FF9800',
                '#E65100',
                '#FF5722',
                '#DD2C00'
            ],
            hoverBackgroundColor: [
                '#B71C1C',
                '#880E4F',
                '#4A148C',
                '#673AB7',
                '#311B92',
                '#3F51B5',
                '#1A237E',
                '#2196F3',
                '#0D47A1',
                '#81D4FA',
                '#84FFFF',
                '#00BCD4',
                '#006064',
                '#A7FFEB',
                '#009688',
                '#004D40',
                '#4CAF50',
                '#1B5E20',
                '#00C853',
                '#8BC34A',
                '#33691E',
                '#CDDC39',
                '#827717',
                '#FFEB3B',
                '#C6FF00',
                '#FF9800',
                '#FF6F00',
                '#FF9800',
                '#E65100',
                '#FF5722',
                '#DD2C00'
            ]
        }]
    };

    if(props.showChart) {
        return (
            <div>
                <h2>Market Diversification</h2>
                <div className="flex">
                    <div className="flex-col w100">
                        <Pie data={pieData} options={optionsData} />
                    </div>
                </div>
            </div>
        )
    } else {
        return '';
    }


}

export default StockMarketPieChart;