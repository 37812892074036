import React from 'react'

class ToggleHoldingsButton extends React.Component {

    render() {
        if(!this.props.showHoldings) {
            return <button className="holdingsBtn" onClick={() => this.props.toggleShowHoldings()}>Close</button>;
        } else {
            return <button  className="holdingsBtn" onClick={() => this.props.toggleShowHoldings()}>Show Porftolio</button>;
        }
    }
}

export default ToggleHoldingsButton