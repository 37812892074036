import React from 'react'

class AddTransactionButton extends React.Component {

    render() {
        const container = {
            symbol: this.props.stock.symbol,
            price: this.props.stock.price,
            movementPct: 0,
            movementAmt: 0,
            quantity: 5,
            purchaseDate: this.props.date,
            purchasePrice: this.props.stock.price
        }

        return <button className="btn btn-light" onClick={() => this.props.addTransaction({
            'quantity': this.props.buyQuantity,
            'security': container
        })}>{this.props.btnLabel}</button>;

    }
}

export default AddTransactionButton