const percentFormat = (num, noPlus) => {    
    if (num > 0) {
        const str = noPlus ? '' : '+';
        return str + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '%';
    } else if (num < 0) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '%';
    } else {
        return '0%';
    }
};

export default percentFormat