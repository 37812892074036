import twoDigitNumber from './TwoDigitNumber'

const getMovingAverages = (priceHistoryArray) => {

    const originalPricesArray = priceHistoryArray.slice(0);

    const mostRecentPriceHistoryArray = (numberOfDays) => {
        let returnArr = originalPricesArray.slice(0);
        returnArr.reverse()
        returnArr = returnArr.slice(0, Math.min(returnArr.length, numberOfDays));
        return returnArr;
    }

    const computeAverage = (numberOfDays) => {
        let average = 0;

        const priceHistoryArray = mostRecentPriceHistoryArray(numberOfDays);

        priceHistoryArray.forEach((price) => {
            average = average + price
        });

        average = twoDigitNumber(average / priceHistoryArray.length);

        return average;
    }    

    let movingAverages = {
        week: computeAverage(7),
        tenDay: computeAverage(10),
        thirtyDay: computeAverage(30),
        sixtyDay: computeAverage(60),
        ninetyDay: computeAverage(90),
        sixMonth: computeAverage(180),
        twoHundredDay: computeAverage(200),
        ytd: computeAverage(365),
        max: computeAverage(priceHistoryArray.length)
    };

    return movingAverages;

}

export default getMovingAverages