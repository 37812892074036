import { React } from 'react'
import {Line} from 'react-chartjs-2'
import currencyFormat from '../utilities/CurrencyFormat'

import getMovingAverages from '../utilities/GetMovingAverages'

function SelectedSecurityChart(props) {

    // security
    // history array with correct security types
    // days to filter by (optional)
    const isStock = props.stocks.findIndex((element) => element.symbol === props.selectedSecurity.symbol) !== -1;
    let filterDays = props.filterDays;
    let historyArr = props.history.slice(0);
    const selectedSecurity = props.selectedSecurity;

    // Remove data older than 1 year
    if(historyArr.length > filterDays) {
        historyArr = historyArr.slice(Math.max(historyArr.length - filterDays, 0));
    }

    const filteredHistoryArr = isStock ? historyArr.filter((historyObj) => {
        return historyObj.stocks.findIndex((stock) =>
        stock.symbol === props.selectedSecurity.symbol) !== -1;
    }) : null;

    const hasHistory = isStock ? filteredHistoryArr.length > 0 : historyArr.length > 0;
    
    if(isStock) {
        historyArr = filteredHistoryArr;
    }

    let chartValues = [];
    let chartLabels = [];
    let data = [];

    if(hasHistory) {
        historyArr.forEach((history) => {
            // Add the label to the labels array
            chartLabels.push(history.date.toLocaleDateString());
    
            // Find the stock and add its price to the values array
            const historySecurities = isStock ? history.stocks : history.funds;
            const historySecurityIndex = historySecurities.findIndex((security) =>
                security.symbol === selectedSecurity.symbol
            );

            chartValues.push(historySecurities[historySecurityIndex].price);
        });


        const today = new Date();
        today.setDate(today.getDate() + props.ticks - 7);

        chartLabels.push(today.toLocaleDateString());
        chartValues.push(selectedSecurity.price);

        const title = props.title ? props.title : selectedSecurity.symbol + ' Price';
        
        let twoHundredDayAveragesArr = [];

        const movingAveragesObj = getMovingAverages(chartValues);
        const populateSingleValueDataArrays = () => {
            for (let i = 0; i < chartValues.length; i++ ) {
                twoHundredDayAveragesArr.push(movingAveragesObj.twoHundredDay);
            }
        }

        populateSingleValueDataArrays();

        data = {
            labels: chartLabels,
            datasets: [
              {
                label: title,
                fill: true,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: chartValues
              },
              {
                label: '200-Day Moving Avg',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: twoHundredDayAveragesArr
              }
            ]
          };

    } 

    // Can render price on the data label
    const options = props.isPrice ? {
        tooltips: {
            callbacks: {
               label: function(tooltipItem, data) {
                      return 'Price: ' + currencyFormat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], true);
               }
            }
        }
    } : {};

    // Render
    if(hasHistory) {
        return (
            <div className="w100">
                <Line data={data} options={options} />
            </div>
        )
    } else {
        return (
            <div className="w100">
                <p>Generating history...</p>
            </div>
        )
    }

}

export default SelectedSecurityChart;