import { React } from 'react'
import percentFormat from './../utilities/PercentFormat'
import numberFormat from './../utilities/NumberFormat'
import twoDigitNumber from './../utilities/TwoDigitNumber'

const StockMarketTotal = (props) => {
    
    let todayGainsSymbol = '';
    let totalGainsSymbol = '';

    const todayGains = props.stockAverage - props.prevTickValues.stockAverage;
    if(todayGains !== 0) {
        todayGainsSymbol = todayGains > 0 ? '+' : '-';
    }

    const totalGains  = props.stockAverage - props.startingStockAverage;
    if(totalGains !== 0) {
        totalGainsSymbol = totalGains > 0 ? '+' : '-';
    }


    return(
        <div className="flex flex-col w100">
            <h2 className="mt-0 mb-0">Stock Market: { numberFormat(props.stockAverage) }</h2>
            <div className="flex mb-10 w100">
                <h3 className="mt-0 text-center w100 mb-0"><small>Today: {todayGainsSymbol}{ numberFormat(twoDigitNumber(props.stockAverage - props.prevTickValues.stockAverage)) }  ({ percentFormat((props.stockAverage - props.prevTickValues.stockAverage) / props.prevTickValues.stockAverage * 100) })</small></h3>
                <h3 className="mt-0 text-center w100 mb-0"><small>Total: {totalGainsSymbol}{ numberFormat(twoDigitNumber((props.stockAverage - props.startingStockAverage))) } ({ percentFormat((props.stockAverage - props.startingStockAverage) / props.startingStockAverage * 100) })</small></h3>
            </div>
        </div>   
    )
}

export default StockMarketTotal;