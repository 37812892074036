const portfolioRisk = (cashPct, mfPct, stocksPct) => {

    let mutualFundsPortionOfHoldings = 100 * (stocksPct + mfPct) / mfPct;

    let portfolioDiversification = '';
    if(cashPct > 80) {
        portfolioDiversification = 'Most Conservative';
    } else if (cashPct > 60) {
        portfolioDiversification = 'Conservative';
    } else if (cashPct > 40 && mutualFundsPortionOfHoldings >= 50) {
        portfolioDiversification = 'Balanced';
    } else if ((cashPct > 40 && mutualFundsPortionOfHoldings < 50)
        || ((cashPct < 40 && cashPct > 20) && mutualFundsPortionOfHoldings > 50)) {
        portfolioDiversification = 'Aggressive';
    } else if ((cashPct < 20)
        || (cashPct < 40 && mutualFundsPortionOfHoldings < 50)) {
        portfolioDiversification = 'Most Aggressive';
    }

    // Greater than 60% cash is conservative
    // Greater than 80% cash is most conservative
    // Between 40% - 60% Cash characterization depends on percent of remaining in stocks and mfs
        // Majority in funds = balanced, stocks = aggressive
    // All characterizations below 40% cash are aggressive at minimum
    // If below 40% and majority of remaining is stock that's most aggressive
    // Below 20% cash is most aggressive


    return portfolioDiversification;
}

export default portfolioRisk