import twoDigitNumber from './TwoDigitNumber';

const getPeriodHigh = (stockObj, stocksHistoryArr, stocksArr, newStockPrice, days) => {

    let stocksHistory = stocksHistoryArr.slice(0);
    const currentStocks = stocksArr.slice(0);
    let maxValue = Math.max(stockObj.price, newStockPrice);

    if (stocksHistory.length > days) {
        stocksHistory = stocksHistory.slice(Math.max(stocksHistory.length - days, 0));
    }

    // console.log('================');
    // console.log('Stock: ' + stockObj.symbol);
    // console.log('newStockPrice: ' + newStockPrice);
    // console.log('stockObj.price: ' + stockObj.price);
    // console.log('maxValue (start): ' + maxValue);

    // let cycles = 0;

    if(stocksHistory.length > 0) {
        currentStocks.forEach((stock) => {
            // cycles++;
            if(stock.symbol === stockObj.symbol) {
                stocksHistory.forEach((stockHistory) => {
                    // cycles++;
                    
                    const stocksOrFundsHistory = stockHistory.stocks ? stockHistory.stocks : stockHistory.funds;

                    stocksOrFundsHistory.forEach((historyStock) => {
                        // cycles++;
                        if(stock.symbol !== historyStock.symbol || (stock.symbol === historyStock.symbol && historyStock.price < maxValue)) {
                            return;
                        }

                        // console.log('historyStock.price: ' + historyStock.price);
                        maxValue = Math.max(newStockPrice, historyStock.price);
                    })
                });
            }

        });
    }

    // console.log('52 Week High: ' + twoDigitNumber(maxValue));
    // console.log(stockObj.symbol + ' Cycles: ' + cycles);
    // console.log(' ');
    return twoDigitNumber(maxValue);

}

export default getPeriodHigh