import { React } from 'react'
import currencyFormat from '../utilities/CurrencyFormat'
import numberFormat from '../utilities/NumberFormat'

const BankruptHolding = (props) => {

    const costBasis = currencyFormat((props.holdingItem.totalLost / props.holdingItem.quantity), true);
    const lastPrice = currencyFormat((props.holdingItem.valueAtTimeOfLoss / props.holdingItem.quantity), true);

    return (
        <tr>
            <td><strong>{props.holdingItem.stock}</strong></td>
            <td>{numberFormat(props.holdingItem.quantity, true)}</td>
            <td>{costBasis}</td>
            <td>{lastPrice}</td>
            <td>{currencyFormat(props.holdingItem.totalLost, true)}</td>
            <td>{currencyFormat(props.holdingItem.valueAtTimeOfLoss, true)}</td>
            <td>{props.holdingItem.date.toLocaleDateString()}</td>
        </tr>
    )
}

export default BankruptHolding;