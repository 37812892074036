
const portfolioDiversification = (holdings, balance, holdingsValueProp) => {

    const roundToZero = (num) => {
        if(num < 0.005) {
            return 0;
        } else {
            return num;
        }
    }

    const cash = balance;
    const holdingsValue = holdingsValueProp;

    const filteredHoldings = holdings.filter((holding) => 
        holding.symbol === 'ALLX'
    );
    const hasMutualFunds = filteredHoldings.length > 0;

    let initialValue = 0;
    const allxSharesQuantity = filteredHoldings.reduce((accumulator, holding) => accumulator + holding.quantity, initialValue);

    const fundsTotal = hasMutualFunds ? roundToZero(allxSharesQuantity * filteredHoldings[0].price) : 0;

    const stocksValue = roundToZero(holdingsValue - fundsTotal);

    const portfolioTotal = holdingsValue + cash;

    const cashPct = cash/portfolioTotal;
    const stocksPct = stocksValue / portfolioTotal;
    const mfPct = fundsTotal / portfolioTotal;

    const displayCashPct = cashPct * 100;
    const displayStocksPct = stocksPct * 100;
    const displayMfPct = mfPct * 100;

    return {
        'cashPct': cashPct,
        'stocksPct': stocksPct,
        'mfPct': mfPct,
        'displayCashPct': displayCashPct,
        'displayStocksPct': displayStocksPct,
        'displayMfPct': displayMfPct
    }
}

export default portfolioDiversification