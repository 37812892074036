import twoDigitNumber from './TwoDigitNumber'
import percentFormat from './PercentFormat';
import getRandomStock from './GetRandomStock';
import numberFormat from './NumberFormat';
import currencyFormat from './CurrencyFormat'
import randomNumber from './RandomNumber';
import getMovingAverages from './GetMovingAverages'

const headlines = (headlineData) => {

    let headlinesArr = [];
    let marketHeadlinesFinalArr = [];
    let mainHeadline = '';
    let fiftyTwoWeeksArr = [];

    // Utilities

    const percentChange = (today, previous) => {
        return Math.abs(Math.round(((today - previous) / previous) * 100))
    }

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const getRandomAdj = (adjList) => {
        return adjList[getRandomInt(0, adjList.length - 1)];
    }

    // End of Utilities

    const pointsChange = numberFormat(twoDigitNumber(headlineData.stockAverage - headlineData.prevStockAverage));
    const flatMsg = 'Stock Market Flat Today';
    const bullHeadline = pointsChange > 1 ? 'Stock Market Gains ' + pointsChange + ' Points' : flatMsg;
    const bearHeadline = pointsChange > 1 ? 'Stock Market Falls '  + pointsChange + ' Points' : flatMsg;
    let regularHeadlinesArr = [];

    const declineAdjectives = ['Plunges', 'Plummets', 'Tumbles', 'Sinks', 'Dips', 'Declines', 'Falls', 'Shrinks', 'Drops', 'Slips', 'Slides'];
    const increaseAdjectives = ['Grows', 'Soars', 'Rockets Up', 'Surges', 'Rises', 'Leaps', 'Jumps', 'Spikes', 'Pops', 'Runs Up', 'Adds'];

    const adjectivePluralizer = (adjective) => {
        const firstSpaceIndex = adjective.indexOf(' ');
        if(firstSpaceIndex === -1) {
            return adjective.substring(0, adjective.length - 1);
        } else {
            const firstPart = adjective.slice(0, firstSpaceIndex);
            const lastPart = adjective.slice(firstSpaceIndex);

            return firstPart.substring(0, firstPart.length - 1) + lastPart;

        }
    }

    const declineAdjectivesPlural = declineAdjectives.map(adjectivePluralizer);
    const increaseAdjectivesPlural = increaseAdjectives.map(adjectivePluralizer);

    const atYearlyHighs = headlineData.stocks.filter((stock) => {
        return (stock.price === (Math.round(stock.yearlyHigh * 100) / 100)) && stock.yearlyLow !== stock.yearlyHigh;
    });

    const atRiskPrice = 7;
    const nearBankruptCompanies = headlineData.stocks.filter((stock) => {
        return stock.price < atRiskPrice && (stock.price !== 0);
    });

    const bankruptCompanies = headlineData.stocks.filter((stock) =>
        stock.price === 0
    );

    // Federal Reserve statement
    // Measures health based on diversification risk, companies near bankruptcy, percent of companies near yearly highs/lows
    // Averages improving

    // Get history array of all funds and reverse the order so the dates will be top to bottom
    // Use price history of the ALLX fund to determine market movements
    let allFundsArr = headlineData.fundsHistory.slice(0);
    allFundsArr.reverse();

    const averageToday = headlineData.stockAverage;
    const averageYesterday = headlineData.prevStockAverage;

    // Get Moving Averages

    let movingAverages = {};

    const fundsHistoryArrayForCopy = [];
    fundsHistoryArrayForCopy.push(averageToday, averageYesterday);
    let historicPrices = {
        thirtyDayHigh: averageToday,
        thirtyDayLow: averageToday,
        sixtyDayHigh: averageToday,
        sixtyDayLow: averageToday,
        yearHigh: averageToday,
        yearLow: averageToday,
        weekHigh: averageToday,
        weekLow: averageToday,
        week: averageToday
    };

    const totalDays = headlineData.ticks + 2;

    if(allFundsArr.length > 0) {
        historicPrices.yearHigh = Math.max(allFundsArr[0].funds[0].yearlyHigh, averageToday);
        historicPrices.yearHigh = Math.max(historicPrices.yearHigh, averageYesterday);
        historicPrices.yearLow = Math.min(allFundsArr[0].funds[0].yearlyLow, averageToday);
        historicPrices.yearLow = Math.min(historicPrices.yearLow, averageYesterday);

        for(var i = 0; i < allFundsArr.length; i++) {
            fundsHistoryArrayForCopy.push(allFundsArr[i].funds[0].price);            
            let marketAverage = allFundsArr[i].funds[0].price;
    
            historicPrices.week = i === 5 ? marketAverage : historicPrices.week;
            historicPrices.thirtyDays = i === 28 ? marketAverage : historicPrices.thirtyDays;
            historicPrices.sixtyDays = i === 58 ? marketAverage : historicPrices.sixtyDays;
            historicPrices.ninetyDays = i === 88 ? marketAverage : historicPrices.ninetyDays;
            historicPrices.sixMonths = i === 178 ? marketAverage : historicPrices.sixMonths;

            historicPrices.weekHigh = i < 6 ? Math.max(marketAverage, historicPrices.weekHigh) : historicPrices.weekHigh;
            historicPrices.weekLow = i < 6 ? Math.min(marketAverage, historicPrices.weekLow) : historicPrices.weekLow;

            historicPrices.thirtyDayHigh = i < 29 ? Math.max(marketAverage, historicPrices.thirtyDayHigh) : historicPrices.thirtyDayHigh;
            historicPrices.thirtyDayLow = i < 29 ? Math.min(marketAverage, historicPrices.thirtyDayLow) : historicPrices.thirtyDayLow;
            historicPrices.sixtyDayHigh = i < 59 ? Math.max(marketAverage, historicPrices.sixtyDayHigh) : historicPrices.sixtyDayHigh;
            historicPrices.sixtyDayLow = i < 59 ? Math.min(marketAverage, historicPrices.sixtyDayLow) : historicPrices.sixtyDayLow;

            if((i < 364 && i === allFundsArr.length - 1) || i === 363) {
                historicPrices.oneYear = marketAverage;
            }

        }
        
        fundsHistoryArrayForCopy.reverse();
        movingAverages = getMovingAverages(fundsHistoryArrayForCopy);

    }

    // Market Beat
    // Reports if the market is below thirty, sixty, ninety day, or six month moving average
    // Reports market returns YTD
    // Reports market returns over past month
    // Add weekly + 10 day performance data

    let marketBeatStr = '';
    let secondaryMarketBeatStr = '';

    const weekReport = totalDays - 1 >= 7 && (totalDays - 1) % 7 === 0;
    const thirtyDayReport = totalDays - 1 >= 30 && (totalDays - 1) % 30 === 0;
    const ytdReport = totalDays - 1 >= 10 && (totalDays - 1) % 10 === 0;

    if(allFundsArr.length >= 28) {
          
        if(averageToday < (movingAverages.week * 0.8) && averageToday < (movingAverages.tenDay * 0.8) && averageToday < (movingAverages.thirtyDay * 0.8) && percentChange(averageToday, historicPrices.week) > 7) {
            marketBeatStr = 'Market Beat: Flash Crash Hammers Market, Average Down ' + percentChange(averageToday, historicPrices.week) + '% in a Week';
        } else if(averageToday < (movingAverages.thirtyDay * 0.8)) {
            marketBeatStr = 'Market Beat: Bear Signals Spook Market, Stocks Trading ' + percentChange(averageToday, movingAverages.thirtyDay) + '% Below 30-Day Averages';
        }

        if(averageToday > (movingAverages.thirtyDay * 1.2)) {

            const period = percentChange(averageToday, historicPrices.week) > percentChange(averageToday, historicPrices.thirtyDays) ? percentChange(averageToday, historicPrices.week) + '% in Big Week' : percentChange(averageToday, historicPrices.thirtyDays) + '% in 30 Days'; 
            const hotMarket = (averageToday >= historicPrices.yearHigh) ? 'Average Hits New 52 Week High' : 'Average Up ' + period;

            marketBeatStr = 'Market Beat: Stocks Rocket, ' + hotMarket;
        
        } else if((averageToday / historicPrices.yearLow) > 1.2
            && averageToday > (movingAverages.week * 1.2)
            && averageToday > (movingAverages.tenDay * 1.2)
            && averageToday > (movingAverages.thirtyDay * 1.2)) {
            
            const extraStr = (averageToday >= historicPrices.yearHigh) ? 'Average Hits New 52 Week High' : 'Stocks Trading ' + percentChange(averageToday, movingAverages.thirtyDay) + '% Above 30-Day Averages';
            marketBeatStr = 'Market Beat: Investors Cheer Bull Market, ' + extraStr;

        } else if(averageToday >= historicPrices.yearHigh) {
            marketBeatStr = 'Market Beat: Average Hits New 52 Week High';
        } else if(averageToday <= historicPrices.yearLow) {
            marketBeatStr = 'Market Beat: Average Hits New 52 Week Low';
        }

    }

    if(weekReport) {
        
        let reportPct = '';

        const averageUp = averageToday / historicPrices.week;
        
        let description = '';
        if(averageUp > 1.01) {
            description = 'Up';
            reportPct = percentChange(averageToday, historicPrices.week) + '%';
        } else if (averageUp < 0.99) {
            description = 'Down';
            reportPct = percentChange(averageToday, historicPrices.week) + '%';
        } else {
            description = 'Flat';
        }

        secondaryMarketBeatStr = 'Market Beat: Markets ' + description + ' ' + reportPct + ' This Week';

    }

    if(ytdReport) {
        const averageUp = averageToday / historicPrices.oneYear;
        let description = '';
        let reportPct = '';
        if(averageUp > 1.01) {
            description = 'Up';
            reportPct = Math.abs(Math.round((averageUp - 1) * 100)) + '%';
        } else if (averageUp < 0.99) {
            description = 'Down';
            reportPct = Math.abs(Math.round((averageUp - 1) * 100)) + '%';
        } else {
            description = 'Flat';
        }
        secondaryMarketBeatStr = 'Market Beat: Markets ' + description + ' ' + reportPct + ' YTD';
    }

    if(thirtyDayReport) {
        const averageUp = averageToday / historicPrices.thirtyDays;
        let description = '';
        let reportPct = '';
        const thirtyDayPct = Math.abs(Math.round((averageUp - 1) * 100));
        if(averageUp > 1.01) {
            description = 'Up';
            reportPct = thirtyDayPct + '% in';
        } else if (averageUp < 0.99) {
            description = 'Down';
            reportPct = thirtyDayPct + '% in';
        } else {
            description = 'Flat';
            reportPct = 'Past'
        }
        

        const yearAverageUp = averageToday / historicPrices.oneYear;
        const yrUpPct = Math.abs(Math.round((yearAverageUp - 1) * 100));
        let ytdDescription = '';
        let ytdReportPct = '';
        let ytdSuffix = ' YTD';
        let comma = ', ';
        if(yearAverageUp > 1.01) {
            ytdDescription = 'Up';
            ytdReportPct = yrUpPct + '%';
        } else if (yearAverageUp < 0.99) {
            ytdDescription = 'Down';
            ytdReportPct = yrUpPct + '%';
        } else {
            ytdDescription = 'Flat';
        }

        if(description === ytdDescription || yrUpPct === thirtyDayPct) {
            ytdDescription = '';

            if(yrUpPct === thirtyDayPct) {
                ytdReportPct = '';
                ytdSuffix = '';
                comma = '';
            }
        }

        ytdReportPct += ytdSuffix;

        secondaryMarketBeatStr = 'Market Beat: Markets ' + description + ' ' + reportPct + ' 30 Days' + comma + ytdDescription + ' ' + ytdReportPct;
    
    }

    // Randomly Display 1/3 of the time when no other messages
    if((averageToday / historicPrices.yearHigh) < 0.8
        && percentChange(averageToday, historicPrices.yearHigh) > percentChange(averageToday, historicPrices.yearLow)
        && secondaryMarketBeatStr.length === 0
        && Math.random() > 0.67) {
            secondaryMarketBeatStr = 'Market Beat: Average ' + percentChange(averageToday, historicPrices.yearHigh) + '% Below Year\'s Peak';
    } else if((averageToday / historicPrices.yearLow) > 1.2
        && percentChange(averageToday, historicPrices.yearHigh) < percentChange(averageToday, historicPrices.yearLow)
        && secondaryMarketBeatStr.length === 0
        && Math.random() > 0.67) {
            secondaryMarketBeatStr = 'Market Beat: Average ' + percentChange(averageToday, historicPrices.yearLow) + '% Above Year\'s Low';
    }

    if(marketBeatStr.length > 0) {
        headlinesArr.push({
            headline: marketBeatStr,
            securities: null
        });
    }
    if(secondaryMarketBeatStr.length > 0) {
        headlinesArr.push({
            headline: secondaryMarketBeatStr,
            securities: null
        });
    }


    // If one or more companies makes up more than 15% of the market average warn of volatility

    let maxRatio = 0;
    const diversificationRisk = headlineData.stocks.filter((stock) => {
        maxRatio = maxRatio < (stock.price / headlineData.stockAverage) && (stock.price / headlineData.stockAverage) > 0.15 ? (stock.price / headlineData.stockAverage) : maxRatio;
        return (stock.price / headlineData.stockAverage) > 0.15
    }).length;

    if(bankruptCompanies.length === 0) {
        if(diversificationRisk > 3 || (maxRatio / 0.15) > 3) {
            headlinesArr.push({
                headline: 'Federal Reserve: "High Market Volatility Expected to Continue"',
                securities: null
            });
        } else if(diversificationRisk === 2 || (maxRatio / 0.15) > 1.5) {
            headlinesArr.push({
                headline: 'Federal Reserve: "High Market Volatility Continues"',
                securities: null
            });
        } else if(diversificationRisk === 1) {
            headlinesArr.push({
                headline: 'Federal Reserve: "Expect High Market Volatility"',
                securities: null
            });
        }
    }


    const bankruptcyRisk = nearBankruptCompanies.length;
    let bankruptcyWarningMessage = '';
    let rateSuggestion = Math.random() > 0.5 ? 'Increase' : 'Decrease';
    switch(bankruptcyRisk) {
        case 0:
            const fundamentals = diversificationRisk ? '' : ', Solid Fundamentals';
            bankruptcyWarningMessage = 'Forbes: Investors Cheer Strong Economy' + fundamentals
            break;
        case 1:
        case 2:
            bankruptcyWarningMessage = 'WSJ: Banks Suggest Interest Rate ' + rateSuggestion
            break;
        case 3:
        case 4:
            const fearIs = Math.random() > 0.5 ? 'Fear is Real' : 'Fears Overblown';
            bankruptcyWarningMessage = bankruptCompanies.length > 0 ? 'NYT: Wave of Bankruptcies Feared' : 'NYT: Bankruptcy Risk? Insiders Say ' + fearIs;
            break;
        case 5:
        case 6:
        case 7:
            bankruptcyWarningMessage = Math.random() > 0.5 ? 'Bloomberg: Companies Raise $' + Math.max(100, Math.round(randomNumber(bankruptcyRisk * 100))) + ' Million in New Debt' : 'Bloomberg: Corporate Lenders Raise Collateral Requirements';
            break;
        case 8:
        case 9:
        case 10:
            bankruptcyWarningMessage = 'MarketWatch: Failure Risk? ' + bankruptcyRisk + ' Small Companies in Danger';
            break;
        default:
            bankruptcyWarningMessage = 'WSJ: On the Brink? ' + Math.round((bankruptcyRisk/30) * 100) + '% of Stock Market Near Bankruptcy';
    }
    if((Math.random() * bankruptcyRisk) > 0.5 && bankruptcyRisk >= 3) {
        headlinesArr.push({
            headline: bankruptcyWarningMessage,
            securities: null
        });
    } else if(Math.random() > 0.5) {
        headlinesArr.push({
            headline: bankruptcyWarningMessage,
            securities: null
        });
    }

    if(bankruptCompanies.length > 0) {
        
        let bankruptCompaniesList = '';
        if(bankruptCompanies.length === 1) {
            headlinesArr.push({
                headline: bankruptCompanies[0].symbol + ' Declares Bankruptcy! Shareholders Wiped Out!',
                securities: null
            })
        } else if(bankruptCompanies.length === 2) {
            headlinesArr.push({
                headline: bankruptCompanies[0].symbol + ' and ' + bankruptCompanies[1].symbol + ' Declare Bankruptcy! Shareholders Wiped Out!',
                securities: null
            })
        } else {
            for(var bk = 0; bk <= bankruptCompanies.length - 2; bk++) {
                if(bk !== bankruptCompanies.length - 2) {
                    bankruptCompaniesList += bankruptCompanies[bk].symbol + ', ';
                } else {
                    bankruptCompaniesList += bankruptCompanies[bk].symbol + ', and ' + bankruptCompanies[bk + 1].symbol;
                }
            }

            headlinesArr.push({
                headline: bankruptCompaniesList + ' Declare Bankruptcy! Shareholders Wiped Out!',
                securities: null
            })
        }
        
    }

    const newOnTheExchange = headlineData.stocks.filter((stock) =>
        stock.yearlyLow === stock.yearlyHigh
    );

    if(newOnTheExchange.length > 0) {
        
        let newCompaniesList = '';
        if(newOnTheExchange.length === 1) {
            headlinesArr.push({
                headline: newOnTheExchange[0].symbol + ' Debuts on the Stock Market at ' + currencyFormat(newOnTheExchange[0].price, true) + ' per Share',
                securities: [newOnTheExchange[0]]
            })
        } else if(newOnTheExchange.length === 2) {
            headlinesArr.push({
                headline: newOnTheExchange[0].symbol + ' and ' + newOnTheExchange[1].symbol + ' Debut on the Stock Market at ' + currencyFormat(newOnTheExchange[0].price, true) + ' per Share',
                securities: [newOnTheExchange[0], newOnTheExchange[1]] 
            });
        } else {

            let newSecurities = [];
            for(var n = 0; n <= newOnTheExchange.length - 2; n++) {
                if(n !== newOnTheExchange.length - 2) {
                    newCompaniesList += newOnTheExchange[n].symbol + ', ';
                    newSecurities.push(newOnTheExchange[n]);
                } else {
                    newCompaniesList += newOnTheExchange[n].symbol + ', and ' + newOnTheExchange[n + 1].symbol;
                    newSecurities.push(newOnTheExchange[n], newOnTheExchange[n + 1]);
                }
            }

            headlinesArr.push({
                headline: newCompaniesList + ' Debut on the Stock Market at ' + currencyFormat(newOnTheExchange[0].price, true) + ' per Share',
                securities: newSecurities
            });
        }
        
    }

    let highestPricesSorted = headlineData.stocks.slice(0);
    highestPricesSorted = highestPricesSorted.sort(function (a, b) {
        return b.price - a.price;
    });

    const highestPricesNoYearHighs = highestPricesSorted.filter((stock) => {
        return stock.price !== (Math.round(stock.yearlyHigh * 100) / 100)
    });

    const replaceHeadlineVars = (wholeStr, stock) => {

        let amount = getRandomInt(5, 10);
        let today = new Date();
        today.setDate(today.getDate() + headlineData.ticks);
        let nextYearText = today.getFullYear() + 1;
        let thisMonthText = today.toLocaleString('default', { month: 'long' });
        let nextMonth = new Date(today.setMonth(today.getMonth() + 1));
        let nextMonthText = nextMonth.toLocaleString('default', { month: 'long' });
        let smallAmount = getRandomInt(3, 5);

        wholeStr = wholeStr.replace('{company}', stock.symbol);
        wholeStr = wholeStr.replace('{amount}', amount);
        wholeStr = wholeStr.replace('{smallAmount}', smallAmount);
        wholeStr = wholeStr.replace('{month}', thisMonthText);
        wholeStr = wholeStr.replace('{nextYear}', nextYearText);
        wholeStr = wholeStr.replace('{nextMonth}', nextMonthText);
        wholeStr = wholeStr.replace('{year}', today.getFullYear());
        wholeStr = wholeStr.replace('{price}', Math.round(stock.price));

        return wholeStr;
    };

    let lowestPriceSorted = nearBankruptCompanies.slice(0);
    lowestPriceSorted = lowestPriceSorted.sort(function (a, b) {
        return a.price - b.price;
    });

    const nearBankruptcyAboveYearLow = lowestPriceSorted.filter((stock) => {
        return stock.price !== stock.yearlyLow;
    });

    const dangerPrice = 5;
    const justMissedBankruptCompanies = lowestPriceSorted.filter((stock) => {
        return stock.price < dangerPrice;
    });

    if(justMissedBankruptCompanies.length > 0) {
        const totalInDanger = justMissedBankruptCompanies.length;
        const randomInDangerIndex = getRandomInt(0, totalInDanger - 1);
        const randomInDangerStock = justMissedBankruptCompanies[randomInDangerIndex];

        // eslint-disable-next-line
        const inDangerHeadlines = ['Is this the End of {company}?', 'What Happened to {company}?', '{company} Shareholders Seek Reassurance as Stock Drops',
            'Retired Gambler Invests Life Savings in {company}', '50% Chance of {company} Default By Next Week', 'Last Dance? {company} Shareholder',
            'Red Numbers, White-Knuckles for {company} Shareholders', 'Future Bleak for {company}', 'Will Skeptics Get the Last Word on {company}?',
            'Turnaround Story "Highly Unlikely" at {company}', 'Want to Invest in {company}? "Go to Vegas Instead," Say Experts',
            'Has the Music Stopped at {company}?', '{company} Stock Could Go to $0 This Week', '{company} Shareholders Demand Answers',
            '{company} Investors Head for the Exits', 'Legendary Investor says "Too Little, Too Late" for {company}', 'Struggling {company} "Rearranges the Deck Chairs"',
            '"Not Ideal Environment," says {company} CEO', 'Rivals Celebrate {company}\'s Struggles', '{company} Barely Able to Make Payroll',
            '{company} CFO in the Hot Seat as Shares Plunge', '"Broken Promises," Investor Calls for {company} CEO to Step Down',
            'Bankruptcy Rumors Surround {company}', 'Anonymous Insiders Report {company} on Shaky Ground', '"No Comment," {company} CFO on Bankruptcy Rumors',
            'Layoffs Coming to {company} says CFO', '{company} CEO says "All Options on the Table," as Company Falters',
            '{company} "One Downturn Away" From Failure, Say Accounting Experts', '{amount} Reasons {company} May Not See the End of the Week',
            'Lights Blinking Red for {company}', '{amount} Reasons {company} Will Go Bust', 'Day Traders Hope to Cash in on {company}\'s Low Price',
            '{smallAmount} Reasons {company} Will Fail This Week', '{amount} Reasons Not to Give Up on {company}', '{year} Sales Down Big at {company}',
            'Huge Problems Sinking {company}', 'Will New Moves Save {company}?', '"No Future" for {company} say Wall Street Analysts',
            'Difficult Days for {company} CEO', '{company} CEO Under Pressure', '{amount} Lessons from the {company} Disaster',
            '{company} Woes Intensify in {month}', '"Now or Never" for {company}, Insiders Say', 'Walls Close in On {company}',
            '{company} Faces Challenges on All Sides', 'Letter from {company} CEO Seeks to Reassure Investors',
            '{company} Management Scrambles After Shareholder Revolt', '{company} Shareholders Flirting with Disaster',
            '{company} Recovery Would be "A Miracle"',  '{company} Running on Fumes', '{company} Burns Cash to Stay Afloat',
            '{company} Battles to Stay Out of Bankruptcy', '{company} Nearly Goes Under Yesterday',
            '{company} Averts Disaster, For Now', '{company} Can\'t Stop the Bleeding', 'Shareholders Abandon {company} in Droves',
            '{company} Brand in Shambles'
        ];

        const randomHeadlineIndex = getRandomInt(0, (inDangerHeadlines.length - 1));
    
        let headlineText = inDangerHeadlines[randomHeadlineIndex];
        headlineText = replaceHeadlineVars(headlineText, randomInDangerStock);
    
        headlinesArr.push({
            headline: headlineText,
            securities: [randomInDangerStock],
        });

    } else if(nearBankruptcyAboveYearLow.length > 0) {
        const totalAtRisk = nearBankruptcyAboveYearLow.length;
        const randomAtRiskIndex = getRandomInt(0, totalAtRisk - 1);
        const randomAtRiskStock = nearBankruptcyAboveYearLow[randomAtRiskIndex];

        // eslint-disable-next-line
        const atRiskHeadlines = ['{company} on "Borrowed Time" says Finance Guru',
            '"Clock Ticking" for {company} say Wall Street Bears', 'Inside {company}\'s Restructuring Plans for {month}',
            'Still Hope for {company}, But Time is Short', 'Legendary Investor Cuts Losses, Sells Entire Stake in {company}',
            'Legendary Investor Believes {company} Undervalued, Buys {amount}% Stake', '"Don\'t Count on a Comeback" for {company}, Warn Finance Experts',
            '"Don\'t Call us a Penny Stock", Snaps {company} CEO', '"Our Best Days are Ahead," Says {company} CEO', 'Speculators See Opportunity in {company}',
            'Beginning of the End for {company}?', 'Low Price Tests Faith of {company} Investors', 'Speculators Take Big Risks, Bet on {company} Comeback',
            'Big Loans Coming Due in {nextMonth} for {company}', 'Embattled {company} CEO Faces Skeptics at Investor Roundtable', 'Critics of {company} Say Company Still Overvalued',
            'Rattled Investors Grill {company} CEO at Forum', 'Will {company} Be Around in {nextYear}?', 'Legendary Investor "Pessimistic" about {company}\'s Future',
            '{company} Challenges "Temporary" says CEO', '{company} Stays Afloat For Now', '{company} CEO Navigates Choppy Waters in {month}', '{company} Board Considers Share Buybacks',
            'Uncertain Future for {company} CEO', 'Double or Nothing? {company} Investors Weigh Risks in {month}', 'Is {company} Even Worth ${price} per Share?',
            '{amount} Problems {company} Faces in {year}', '{amount} Reasons {company} Will Turn Things Around by {nextMonth}', '{amount} Reasons {company} Won\'t Turn Things Around',
            '{company} CEO Seeks Foreign Investment', 'Shareholders Concerned About Future of {company}', 'Shareholder Open Letter Demands Change at {company}',
            'Legendary Investor Believes {company} "Troubled, Not Broken"', '{company} is "One Downturn Away" from Bankruptcy',
            '"Zero Truth" to Bankruptcy Rumors says {company} CFO', '{company} Recovery Prospects Face Headwinds in {month}', '"Don\'t Write Us Off Yet," Says {company} CEO',
            'Accountant Warns {company} "Not Healthy"', '"Think Twice Before Investing in {company}," Say Experts', 'Low Price, High Risks. Should You Invest In {company}?',
            'Know the Risks Before Investing in {company}', 'Hedge Fund Cuts Losses, Pulls Out of {company}', 'Is {company} Worth the Risk at ${price} per Share?',
            '{amount} Reasons {company} Trades Below Peers', 'Is {company} CEO Right for the Job?', '{company} Needs Good News in {month}',
            'Time to Deliver for {company} CEO', 'Will New Moves Lead to Growth at {company}?', '{amount} Reasons {company} Will Be Back',
            '{amount} Ways {company} Stock Could Recover', 'Will {company} Recover by {nextMonth}?', 'Will {company} See Brighter Days?',
            'New {company} Shareholders Lured by Low Prices, Potential Returns', 'Make or Break Time for {company}, say Analysts',
            '{company} CEO Faces Tough Choices as Bankruptcy Looms', '{company} CEO says Long Term Outlook "Remains Positive"',
            'Big Changes Coming to {company} in {nextMonth}', 'Shareholders Want More from {company} CEO', '{smallAmount} Reasons {company} Shares Could Go to $0 by {nextMonth}',
            'Bankruptcy Rumors "Greatly Exaggerated" says {company} CFO', '{company} More Competitve After Layoffs, Says CEO',
            'Is {company} a Bargain at ${price}? Not So Fast', '{company} CEO Takes Heat for Slashing Costs', 'Can {company} Get Through {month}?',
            'Can {company} Escape the Basement in {month}?', '{company} Shareholders Hope for Quick Turnaround',
            '{company} Management Plays Musical Chairs', '{company} Stock Has Taken a Beating in {year}'
        ];

        const randomHeadlineIndex = getRandomInt(0, (atRiskHeadlines.length - 1));
    
        let headlineText = atRiskHeadlines[randomHeadlineIndex];
        headlineText = replaceHeadlineVars(headlineText, randomAtRiskStock);
    
        headlinesArr.push({
            headline: headlineText,
            securities: [randomAtRiskStock],
        });
    }

    const highestPricesGiants = highestPricesSorted.filter((stock) => {
        return (stock.price / averageToday) > 0.15
    });

    // If any "giants" exist, display a message about them 90% of the time
    const displayGiantsNormalizer = (numberOfGiants) => {
        if(numberOfGiants > 2) {
            return true;
        } else if(numberOfGiants === 2) {
            return Math.random() < 0.8;
        } else if (numberOfGiants === 1) {
            return Math.random() < 0.5;
        } else {
            return false;
        }
    };

    const totalGiants = highestPricesGiants.length;

    if(totalGiants > 0 && displayGiantsNormalizer(totalGiants)) {
        const randomGiantIndex = getRandomInt(0, totalGiants - 1);
        const highestPriceStockAtYearHigh = highestPricesGiants[randomGiantIndex];

        // eslint-disable-next-line
        const highestPriceGiantsHeadlines = ['To the Moon? {company} Keeps Shattering Expectations', 'Nothing Can Stop {company}',
            'How High Can {company} Go?', 'Is {company} Flying Too Close to the Sun?', '{company} CEO Focused on the Future',
            'A Look at {company}, Titan of Wall Street', '{company} Towers Over Wall Street', '"Looking Back We Were Just Lucky", Admits {company} Founder',
            '{company} and the View from the Top', '"Why I\'m Still Holding {company}," Legendary Investor Weighs In', '{amount} Reasons {company} Will Keep Growing',
            'Can Anyone Stop {company}?', 'Is {company} Growth Sustainable?', '"Continuous Improvement," Key for {company} Growth',
            'How {company} Gets it Done', 'It\'s {company} vs The World', '"More." {company} CEO Offers Wall Street One Word',
            '{company} Raises the Bar for Wall Street', '{amount} Reasons {company} is Still a Buy at ${price}',
            '"Lean Makes Green," {company} CEO Shares Philosophy', 'Breaking All the Rules at {company}', '{company} Investors Reap Rewards',
            'Legendary Investor "Missed Out" on {company}', 'A Brief History of The {company} Empire', '{amount} Reasons Investors Love {company}',
            '{company} CEO "Made for the Moment"', '"Why I\'m Selling {company}," Legendary Investor Speaks Out',
            'Open Laughter as Investor Questions {company}\'s Future', '"Right Where We Want to Be," {company} CEO Says',
            '{company} CEO Golfs with {state} Senator', 'Hedge Funds Love {company}', 'Is {company} Overvalued? Analysts Skeptical',
            'Good Times Roll at {company}', '{company} Ready to Dominate {nextYear}', 'When {company} CEO Speaks, Wall Street Listens',
            'Can {company} Stock Keep Flying High?', '{company} Stock Shines Above the Rest', 'Can {company} Keep Minting Money?',
            'Early Investors in {company} Reap Profits', 'How Wall Street King {company} Makes Money', 'What\'s Next for {company}? Wall Street Titan Looks Ahead',
            'How {company} Became the Largest Company on Wall Street', 'Growth at All Costs at {company}', 'Savvy Moves Paying Off for {company}',
            '{company} at the Top of the Food Chain', 'The Data Behind the Rise of {company}', '{company} Brand Key to Soaring Valuation'
        ];

        const randomHeadlineIndex = getRandomInt(0, (highestPriceGiantsHeadlines.length - 1));
    
        let headlineText = highestPriceGiantsHeadlines[randomHeadlineIndex];
        headlineText = replaceHeadlineVars(headlineText, highestPriceStockAtYearHigh);
    
        marketHeadlinesFinalArr.push({
            headline: headlineText,
            securities: [highestPriceStockAtYearHigh],
        });
    

    } else if(highestPricesNoYearHighs.length > 0) {
        const maxIndexForTopItems = Math.min(5, (highestPricesNoYearHighs.length - 1));
        const randomIndex = getRandomInt(0, maxIndexForTopItems);

        const highestPriceStockNotAtYearHigh = highestPricesNoYearHighs[randomIndex];

        // eslint-disable-next-line
        const highestPriceStockNotAtYearHighHeadlines = ['Still a Buy at ${price}? Firms Question {company}\'s High Stock Price',
            'Leading the Market the {company} Way', '{company} CEO is Building "An Empire"', 'Growth Mindset, Zero Barriers at {company}',
            '{company} Shows the Competition How It\'s Done', 'Innovation Key to {company}\'s Future', '${amount} Million Bonus for {company} CEO',
            'Optimism Drives {company}\'s ${price} Share Price', '"Quality is Our Biggest Asset," says {company} CEO', '{company} Undervalued at ${price}, Says Legendary Investor',
            '{company} CEO Brings Unique Experience to the Role', 'Wall Street Likes What it Sees at {company}', '{company} Commands High Share Price',
            '{amount} Reasons to Buy {company} and Hold Forever', '{smallAmount} Reasons {company} Trades Higher Than its Peers', '{company} CEO Talks about What it Takes to Make it',
            '"Can\'t Stop, Won\'t Stop," {company} CEO says', '{amount} Reasons Wall Street Likes {company} in {month}', 'Future Bright for {company}, say Analysts',
            '{amount} Reasons for {company}\'s Success in {year}', '{company} CEO Predicts Strong Sales in {nextMonth}', 'Expectations Build for {company} CEO',
            '{amount} Secrets to {company}\'s Success', '{company} Stands Tall in {year}', '{company} CEO Predicts {amount}% Growth in {year}',
            'Competitors Struggle to Keep Up with {company} in {year}', '{company} Gathers Strength in {month}', '{company} CEO Talks about {nextYear} and Beyond',
            'Optimistic {company} CEO Focuses on Growth', 'High Expectations of {company} CEO', 'Customers Moving to {company} in {year}',
            '"The Best is Yet to Come," says {company} CEO', 'Costs Down, Sales Up at {company} in {month}', 'Pet Insurance? Employee Perks Get a Boost at {company}',
            '{amount} Reasons Customers and Employees Stick with {company}', 'Continuous Improvement the Name of the Game at {company}',
            'Time to Sell? Hedge Funds Weigh Cashing out of {company}', 'Legendary Investor Sells {amount}% of {company} Holdings',
            'Still Time to Invest in {company}', 'Too Late to Invest in {company}?', 'Will {nextYear} be Even Better for {company}?',
            '{company} Has Plenty of Room to Run at ${price} Per Share', 'Retail Investors Jump into {company}', 'Institutional Ownership of {company} Up {amount}%',
            '{company} CEO "The Real Deal," Says Legendary Investor', '{company} CEO\'s "New Deal" Paying Off for Investors', 'Climbing the Hill the {company} Way'
        ];

        const secondTierCategoryHeadlines = ['{company} Reports Strong Sales in {month}', 'New Customer Loyalty Plan Unveiled at {company}',
            '{smallAmount} Reasons {company} Sticks Around', '{smallAmount} Ways {company} Can Reach the Next Level by {nextYear}', '{company} Ready for Lift Off',
            '{company} Leadership Sets Sights on {nextYear}', '{company} Poised to Separate from the Pack', '{smallAmount} Reasons {company} Will Take Off by {nextYear}',
            'Hedge Fund Buys {smallAmount}% Stake in {company}', 'Institutional Ownership of {company} Up {smallAmount}%', '{company} CEO Discusses New Growth Strategy',
            'Job Opportunities Opening Up at {company}', 'Retail Investors Take Notice of {company}', '{company} A Long Term Buy, Say Analysts',
            '"We Play the Long Game," says {company} CEO', '{company} CEO Looking Forward to {nextMonth}',
            'Bullish Investors Like {company} in {month}', '{company}\'s ${price} Stock Price a Bargain, Say Analysts', '"Wall Street Favors the Bold," Says {company} CEO',
            '{company} CEO Sticks to Long Term Vision', '{company} CEO Promises to "Go Bigger"', '{company} and the Future of Wall Street',
            '{company} Outshines Peers in {month}', '{smallAmount} Ideas Paying Off for {company} in {year}', 'Investors Optimistic About {company} in {month}',
            '{company} Invests ${amount} million in Software Upgrade', 'Can {company} Keep Up the Pace?', '{company} Growth Meets Analyst Expectations',
            'Aggressive Cost Controls Pay Off for {company}', '{amount} Ways {company} is Preparing for {nextYear}', '"Forget The Past," Says {company} CEO',
            '{company} CEO Ignites Investors at Conference', 'Early {company} Investors are All Smiles', '{smallAmount} Ways {company} Can Cut Costs',
            '"Be Better Every Day," {company} CEO Reveals Strategy', '"Time On Our Side," Say {company} Investors', '{company} CEO Slashes Costs in {month}',
            '{company} Edges Ahead of Rivals in {month}', 'Can {company} Break Into the Stratosphere?', '{amount} Reasons Analysts See More Upside in {company}'
        ];
    
        if((highestPriceStockNotAtYearHigh.price / averageToday) > 0.06) {
    
            const randomHeadlineIndex = getRandomInt(0, (highestPriceStockNotAtYearHighHeadlines.length - 1));
    
            let headlineText = highestPriceStockNotAtYearHighHeadlines[randomHeadlineIndex];
            headlineText = replaceHeadlineVars(headlineText, highestPriceStockNotAtYearHigh);
    
            headlinesArr.push({
                headline: headlineText,
                securities: [highestPriceStockNotAtYearHigh],
            });
    
        } else if ((highestPriceStockNotAtYearHigh.price / averageToday) > 0.04) {
            const randomHeadlineIndex = getRandomInt(0, (secondTierCategoryHeadlines.length - 1));
    
            let headlineText = secondTierCategoryHeadlines[randomHeadlineIndex];
            headlineText = replaceHeadlineVars(headlineText, highestPriceStockNotAtYearHigh);
    
            headlinesArr.push({
                headline: headlineText,
                securities: [highestPriceStockNotAtYearHigh],
            });
        }

    }

    const atYearlyLows = headlineData.stocks.filter((stock) => {
        return (stock.price === (Math.round(stock.yearlyLow * 100) / 100))
                 && stock.yearlyLow !== stock.yearlyHigh
                 && stock.price !== 0
    });
    
    if(headlineData.bullDay) {
        mainHeadline = 'Markets Rally, Stocks Up ' + percentChange(headlineData.stockAverage, headlineData.prevStockAverage) + '%';
        const leadersText = headlineData.stocks[0].symbol + ' ('+ percentFormat(headlineData.stocks[0].movementPct) +'), ' +
                            headlineData.stocks[1].symbol + ' ('+ percentFormat(headlineData.stocks[1].movementPct) +'), and ' +
                            headlineData.stocks[2].symbol + ' ('+ percentFormat(headlineData.stocks[2].movementPct) +') ' +
                            'Lead Rally';
        marketHeadlinesFinalArr.push(
        {
            headline: leadersText,
            securities: [headlineData.stocks[0], headlineData.stocks[1], headlineData.stocks[2]]
            
        },
        {
            headline: bullHeadline,
            securities: null,
        });
    } else if (headlineData.bearDay) {
    
        let upDownOrFlat = '';
        if((headlineData.stockAverage / headlineData.prevStockAverage) > 1.01) {
            upDownOrFlat = 'Up ' + percentChange(headlineData.stockAverage, headlineData.prevStockAverage) + '%';
        } else if((headlineData.stockAverage / headlineData.prevStockAverage) < 0.99) {
            upDownOrFlat = 'Down ' + percentChange(headlineData.stockAverage, headlineData.prevStockAverage) + '%';
        } else {
            upDownOrFlat = 'Flat';
        }
    
        mainHeadline = headlineData.stockAverage - headlineData.prevStockAverage < 0 ?
                        'Markets Dip, Stocks Down ' + percentChange(headlineData.stockAverage, headlineData.prevStockAverage) + '%' :
                        'Heavy Losses, Markets ' + upDownOrFlat +  + ' As Bankrupt Companies Replaced';
        const losersText = headlineData.stocks[headlineData.stocks.length - 1].symbol + ' ('+ percentFormat(headlineData.stocks[headlineData.stocks.length - 1].movementPct) +'), ' +
                            headlineData.stocks[headlineData.stocks.length - 2].symbol + ' ('+ percentFormat(headlineData.stocks[headlineData.stocks.length - 2].movementPct) +'), and ' +
                            headlineData.stocks[headlineData.stocks.length - 3].symbol + ' ('+ percentFormat(headlineData.stocks[headlineData.stocks.length - 3].movementPct) +') ' +
                            'Lead Declines';
        marketHeadlinesFinalArr.push(
            {
                headline: losersText,
                securities: [
                    headlineData.stocks[headlineData.stocks.length - 1],
                    headlineData.stocks[headlineData.stocks.length - 2],
                    headlineData.stocks[headlineData.stocks.length - 3]
                ]
            },
            {
                headline: bearHeadline,
                securities: null
            }

        );
    } else {
        const gainOrLossText = headlineData.stockAverage - headlineData.prevStockAverage > 0 ? 'Up' : 'Down';
        mainHeadline = 'Stock Market Closes ' + gainOrLossText + ' ' + numberFormat(twoDigitNumber(headlineData.stockAverage - headlineData.prevStockAverage)) + ' Points (' + percentFormat(((headlineData.stockAverage - headlineData.prevStockAverage) / headlineData.prevStockAverage) * 100) + ')';
        
        const bigGains = headlineData.stocks.filter((stock) => {
            return stock.movementPct > 5
        });
    
        const bigDeclines = headlineData.stocks.filter((stock) => {
            return stock.movementPct < -5
        });
    
        const gainReasons = ['After Board Shakeup', 'On Surprise Earnings Growth', 'On Sales Growth Rumors', 'After Analyst Upgrade', 'After New Contract Announced', 'After Lawsuit Settlement', 'On Hedge Fund Investment', 'On Retail Investor Frenzy', 'On Insider Investment'];
        const lossReasons = ['After Analyst Downgrade', 'On Surprise Sales Slump', 'After Investigation Announced', 'After New Lawsuit Filing', 'After Data Breach', 'On Hedge Fund Sale', 'On Short Seller Frenzy', 'Following Insider Sales', 'After Cyberattack'];
        // const lossReasons = ['After Investigation Announced'];

        // For  debugging
        // const gainReasons = ['Because Godzilla'];

        // To Do: Refactor this logic
        // Must be maintained manually, order must align exactly with corresponding gain/loss reason above
        
         // For  debugging
        // const gainReasonsPlural = ['Because Plural Godzilla'];
        const gainReasonsPlural = ['After Board Shakeups', 'On Earnings Surprises', 'On Sales Growth Rumors', 'After Analyst Upgrades', 'After New Deals Announced', 'After Lawsuit Settlements', 'On Hedge Fund Investments', 'On Retail Investor Frenzy', 'On Insider Investments'];
        const lossReasonsPlural = ['After Analyst Downgrades', 'On Surprise Sales Slumps', 'After Investigations Announced', 'After New Lawsuit Filings', 'After Data Breaches', 'On Hedge Fund Sales', 'On Short Seller Frenzy', 'Following Insider Sales', 'After Cyberattack'];
        // const lossReasonsPlural = ['After Investigations Announced'];

        // If two or more stocks are up/down because of:
        // Any reason
        // Combine their message and don't display the percent, eg
        // 'Stock and Stock + pluralized adjective + Pluralized Reason
        // 'Stock, Stock, and Stock' + pluralized adjective + pluralized reason

        // Create a collection of stocks and gain reasons

        const bigGainerStockHeadlines = [];
        let bigGainerReasonCodes = [];
        const duplicateGainerReasonCodes = [];
        let duplicateGainReason = false;

        if(bigGains.length > 0) {
            for(let i = 0; i < bigGains.length; i++ ){

                // Add to the collection
                const gainReasonCode = getRandomInt(0, (gainReasons.length - 1));
                const isDuplicate = bigGainerReasonCodes.indexOf(gainReasonCode) !== -1;
                
                if(isDuplicate) {
                    duplicateGainReason = true;

                    if(duplicateGainerReasonCodes.indexOf(gainReasonCode) === -1) {
                        duplicateGainerReasonCodes.push(gainReasonCode);
                    }
                }

                bigGainerReasonCodes.push(gainReasonCode);
                bigGainerStockHeadlines.push({
                    reasonCode: gainReasonCode,
                    security: bigGains[i]
                });

                // Single gain reason headline code (to move)
                // const gainReason = gainReasons[gainReasonCode];
                // const gainHeadline = bigGains[i].symbol + ' ' + getRandomAdj(increaseAdjectives) + ' ' + percentFormat(bigGains[i].movementPct, false) + ' ' + gainReason;
    
                // marketHeadlinesFinalArr.push({
                //     headline: gainHeadline,
                //     securities: [bigGains[i]]
                // });

            }

            if(duplicateGainReason) {
                    
                duplicateGainerReasonCodes.forEach((code) => {

                    const bigGainerHeadlinesFilteredForDuplicate = bigGainerStockHeadlines.filter((bigGainerStockHeadline) => {
                        return bigGainerStockHeadline.reasonCode === code;
                    });

                    // Remove duplicated reason code from big gainer reasons array
                    // since we're dealing with those now
                    bigGainerReasonCodes = bigGainerReasonCodes.filter((bigGainerCode) => {
                        return bigGainerCode !== code;
                    });

                    const securitiesList = [];

                    for(let i = 0; i < bigGainerHeadlinesFilteredForDuplicate.length; i++ ){
                        securitiesList.push(bigGainerHeadlinesFilteredForDuplicate[i].security);
                    }

                    // Text List
                    let headlineSecuritiesListPart = '';
                    if(securitiesList.length === 2) {
                        headlineSecuritiesListPart = securitiesList[0].symbol + ' and ' + securitiesList[1].symbol;
                    } else {
                        if(securitiesList.length !== 3) {
                            console.log('securitiesList length is not 3');
                            console.log('securitiesList: ' + securitiesList);
                        }
                        for(var hs = 0; hs <= securitiesList.length - 2; hs++) {
                            if(hs !== securitiesList.length - 2) {
                                headlineSecuritiesListPart += securitiesList[hs].symbol + ', ';
                            } else {
                                headlineSecuritiesListPart += securitiesList[hs].symbol + ' and ' + securitiesList[hs + 1].symbol;
                            }
                        }
            
                    }
                    // bankruptcy code

                    const gainReason = gainReasonsPlural[code];
                    const gainHeadline = headlineSecuritiesListPart + ' ' + getRandomAdj(increaseAdjectivesPlural) + ' ' + gainReason;

                    marketHeadlinesFinalArr.push({
                        headline: gainHeadline,
                        securities: securitiesList
                    });

                });

                bigGainerReasonCodes.forEach((bigGainerReasonCode) => {
                    const thisHeadline = bigGainerStockHeadlines.filter((bigGainerStockHeadline) => {
                        return bigGainerStockHeadline.reasonCode === bigGainerReasonCode;
                    });

                    // Add each non duplicate headline as normal
                    for(let i = 0; i < thisHeadline.length; i++ ){
                        const gainReason = gainReasons[bigGainerReasonCode];
                        const gainHeadline = thisHeadline[i].security.symbol + ' ' + getRandomAdj(increaseAdjectives) + ' ' + percentFormat(thisHeadline[i].security.movementPct, false) + ' ' + gainReason;
            
                        marketHeadlinesFinalArr.push({
                            headline: gainHeadline,
                            securities: [thisHeadline[i].security]
                        });
                    }

                });

            } else {

                // Add each non duplicate headline as normal
                for(let i = 0; i < bigGainerStockHeadlines.length; i++ ){
                    const gainReason = gainReasons[bigGainerStockHeadlines[i].reasonCode];
                    const gainHeadline = bigGainerStockHeadlines[i].security.symbol + ' ' + getRandomAdj(increaseAdjectives) + ' ' + percentFormat(bigGainerStockHeadlines[i].security.movementPct, false) + ' ' + gainReason;
        
                    marketHeadlinesFinalArr.push({
                        headline: gainHeadline,
                        securities: [bigGainerStockHeadlines[i].security]
                    });
                }
            }



        }


        // If there are no duplicates in the gain reasons, proceed as above
        // and map each security and headline into the headlines array
        // If there ARE duplicates
        // 1. Create a copy of the array with only non-duplicated reasons (to add to the headlines array at the end)
        // 2. Create a copy of the array with only duplicates AND store the duplicate indices into a new array
        // 3. Loop through the duplicate indices array, and inside the loop create an array from the duplicates filtered by the duplicate index (reason)
        // 4. Use the filtered array to create the gain headline, add it and all securities on the filtered array to the headline
        // 4a. Account for Two and Three or More ONLY in this situation, the One case is covered at the beginning
        // 5. Add the non-duplicated reasons to the headlines array as normal

        // Do this again for the declines
    
        let bigDeclinerStockHeadlines = [];
        let bigDeclinerReasonCodes = [];
        let duplicateDeclinerReasonCodes = [];
        let duplicateDeclineReason = false;

        if(bigDeclines.length > 0) {
            // console.log('=========== NEW DAY ===========');
            // console.log('bigDeclines exist');
            // console.log(bigDeclines);
            for(let i = 0; i < bigDeclines.length; i++ ){
                
                // Add to the collection
                const declineReasonCode = getRandomInt(0, (lossReasons.length - 1));
                const isDuplicateLoss = bigDeclinerReasonCodes.indexOf(declineReasonCode) !== -1;
                
                // console.log('declineReasonCode:' + declineReasonCode);

                if(isDuplicateLoss) {
                    duplicateDeclineReason = true;

                    if(duplicateDeclinerReasonCodes.indexOf(declineReasonCode) === -1) {
                        duplicateDeclinerReasonCodes.push(declineReasonCode);
                    }
                }

                bigDeclinerReasonCodes.push(declineReasonCode);
                bigDeclinerStockHeadlines.push({
                    reasonCode: declineReasonCode,
                    security: bigDeclines[i]
                });

            }

            // console.log('duplicateDeclineReason:' + duplicateDeclineReason);
            if(duplicateDeclineReason) {
                    
                duplicateDeclinerReasonCodes.forEach((code) => {

                    const bigDeclinerHeadlinesFilteredForDuplicate = bigDeclinerStockHeadlines.filter((bigDeclinerStockHeadline) => {
                        return bigDeclinerStockHeadline.reasonCode === code;
                    });

                    // Remove duplicated reason code from big gainer reasons array
                    // since we're dealing with those now
                    bigDeclinerReasonCodes = bigDeclinerReasonCodes.filter((bigDeclinerCode) => {
                        return bigDeclinerCode !== code;
                    });

                    const securitiesList = [];

                    for(let i = 0; i < bigDeclinerHeadlinesFilteredForDuplicate.length; i++ ){
                        securitiesList.push(bigDeclinerHeadlinesFilteredForDuplicate[i].security);
                    }

                    // Text List
                    let headlineSecuritiesListPart = '';
                    if(securitiesList.length === 2) {
                        headlineSecuritiesListPart = securitiesList[0].symbol + ' and ' + securitiesList[1].symbol;
                    } else {
                        if(securitiesList.length !== 3) {
                            console.log('securitiesList length is not 3');
                            console.log('securitiesList: ' + securitiesList);
                        }
                        for(var hs = 0; hs <= securitiesList.length - 2; hs++) {
                            if(hs !== securitiesList.length - 2) {
                                headlineSecuritiesListPart += securitiesList[hs].symbol + ', ';
                            } else {
                                headlineSecuritiesListPart += securitiesList[hs].symbol + ' and ' + securitiesList[hs + 1].symbol;
                            }
                        }
            
                    }
                    // bankruptcy code

                    const lossReason = lossReasonsPlural[code];
                    const lossHeadline = headlineSecuritiesListPart + ' ' + getRandomAdj(declineAdjectivesPlural) + ' ' + lossReason;

                    marketHeadlinesFinalArr.push({
                        headline: lossHeadline,
                        securities: securitiesList
                    });
                    // console.log('Pushed from duplicate block');

                });

                bigDeclinerReasonCodes.forEach((bigDeclinerReasonCode) => {
                    const thisHeadline = bigDeclinerStockHeadlines.filter((bigDeclinerStockHeadline) => {
                        return bigDeclinerStockHeadline.reasonCode === bigDeclinerReasonCode;
                    });

                    // Add each non duplicate headline as normal
                    for(let i = 0; i < thisHeadline.length; i++ ){
                        const lossReason = lossReasons[bigDeclinerReasonCode];
                        const lossHeadline = thisHeadline[i].security.symbol + ' ' + getRandomAdj(declineAdjectives) + ' ' + percentFormat(thisHeadline[i].security.movementPct, false) + ' ' + lossReason;
            
                        marketHeadlinesFinalArr.push({
                            headline: lossHeadline,
                            securities: [thisHeadline[i].security]
                        });

                        // console.log('Pushed from individual block');
                    }

                });


            } else {
                // console.log(lossReasons);
                // Add each non duplicate headline as normal
                
                let usedLossReasons = [];

                for(let j = 0; j < bigDeclines.length; j++ ){
                    
                    let rand = getRandomInt(0, (lossReasons.length - 1));
                    // console.log('Generating code...');
                    if(!usedLossReasons.length && duplicateDeclinerReasonCodes.indexOf(rand) === -1) {
                        // console.log('Generated code: ' + rand);
                        usedLossReasons.push(rand);
                    } else if(usedLossReasons.indexOf(rand) !== -1 || duplicateDeclinerReasonCodes.indexOf(rand) !== -1) {
                        
                        // if(usedLossReasons.indexOf(rand) !== -1) {
                        //     console.log('Code found in usedLossReasons...');    
                        // }

                        // if(duplicateDeclinerReasonCodes.indexOf(rand) !== -1) {
                        //     console.log('Code found in duplicateDeclinerReasonCodes...');    
                        // }
                        
                        // console.log('Regenerating code...');
                        while(usedLossReasons.indexOf(rand) !== -1 || duplicateDeclinerReasonCodes.indexOf(rand) !== -1) {
                            rand = getRandomInt(0, (lossReasons.length - 1));
                        }

                        // console.log('Regenerated code: ' + rand);

                        usedLossReasons.push(rand);

                    } else {
                        // console.log('Generated code: ' + rand);
                        usedLossReasons.push(rand);
                    }
                    // console.log('Final Generated code: ' + rand);
                    const lossReason = lossReasons[rand];
                    // console.log('Individual else block lossReason:' + lossReason);
                    const lossHeadline = bigDeclines[j].symbol + ' ' + getRandomAdj(declineAdjectives) + ' ' + percentFormat(bigDeclines[j].movementPct, false) + ' ' + lossReason;
                    marketHeadlinesFinalArr.push({
                        headline: lossHeadline,
                        securities: [bigDeclines[j]]
                    });
                    // console.log('Pushed from individual else block');
                }
            }  


        }
    
        regularHeadlinesArr.forEach((headline) => {
            headlinesArr.push({
                headline: headline,
                securities: null
            });
        });
    
    }

    if((headlinesArr.length < 2 && (atYearlyLows.length < 1 || atYearlyHighs.length < 1)) || headlinesArr.length === 0) {
        
        const averageMultiple = (headlineData.average / headlineData.startingStockAverage) > 2 ?  Math.round(headlineData.average / headlineData.startingStockAverage) : 0;
        const years = Math.round(headlineData.ticks / 365);

        const reportYears = years > 0 ? years + ' Years' : 'Less Than a Year';

        if(averageMultiple > 1) {
            headlinesArr.push({
                    headline: 'Bubble? Stock Market Up ' + averageMultiple + ' in' + reportYears,
                    securities: null
            });
        }

        const growVsShrink = Math.random() > 0.5 ? 'Grow' : 'Shrink';
        const speculator = 'Investment Guru Predicts ' + getRandomStock(headlineData.stocks).symbol + ' to ' + growVsShrink + ' ' + getRandomInt(20, 30) + '% this Year';

        headlinesArr.push({
            headline: speculator,
            securities: null
        });

        const analystText = Math.random() < 0.5 ? ' Stall Ahead, Analyst Warns' : ' Poised for Liftoff, Analyst Says';
        headlinesArr.push({
            headline: 'Stock Market' + analystText,
            securities: null
        });
    }

    const reportHiLoValuesAfter = 5;
    let yearlyHighSymbols = '';
    let yearlyHighSecurities = [];
    if(atYearlyHighs.length > 0) {
        for(let k = 0; k <= atYearlyHighs.length - 1; k++ ){
            yearlyHighSymbols += yearlyHighSymbols.length !== 0 ? ', ' + atYearlyHighs[k].symbol : atYearlyHighs[k].symbol;
            yearlyHighSecurities.push(atYearlyHighs[k]);
        }
    }
    if(yearlyHighSymbols.length > 0 && headlineData.ticks >= reportHiLoValuesAfter) {

        const yearHighsCount = atYearlyHighs.length > 1 ? ' (' + atYearlyHighs.length + ')' : '';

        fiftyTwoWeeksArr.push({
            headline: '52 Week Highs' + yearHighsCount + ': ' + yearlyHighSymbols,
            securities: yearlyHighSecurities
        });
    }

    let yearlyLowSymbols = '';
    let yearlyLowSecurities = [];
    if(atYearlyLows.length > 0) {
        // console.log(atYearlyLows);
        for(let k = 0; k <= atYearlyLows.length - 1; k++ ){
            yearlyLowSymbols += yearlyLowSymbols.length !== 0 ? ', ' + atYearlyLows[k].symbol : atYearlyLows[k].symbol;
            yearlyLowSecurities.push(atYearlyLows[k]);
        }
    }
    if(yearlyLowSymbols.length > 0  && headlineData.ticks >= reportHiLoValuesAfter) {

        const yearLowsCount = atYearlyLows.length > 1 ? ' (' + atYearlyLows.length + ')' : '';

        fiftyTwoWeeksArr.push({
            headline: '52 Week Lows' + yearLowsCount + ': ' + yearlyLowSymbols,
            securities: yearlyLowSecurities
        });
    }

    // Current:
    // ['', '', '']
    // Desired:
    // [{ headlineText: '', securities: [Security] }]

    return {
        mainHeadlineText: mainHeadline,
        otherHeadlinesList: headlinesArr,
        marketHeadlinesList: marketHeadlinesFinalArr,
        fiftyTwoWeekHiLo: fiftyTwoWeeksArr
    };

}

export default headlines