import { React } from 'react'
import twoDigitNumber from '../utilities/TwoDigitNumber'
import { Pie } from 'react-chartjs-2';
import useWindowDimensions from '../hooks/windowDimensions'
import percentFormat from '../utilities/PercentFormat'

function PorfolioDiversificationChartOnly(props) {

    const roundToZero = (num) => {
        if(num < 0.005) {
            return 0;
        } else {
            return num;
        }
    }

    const cash = props.balance;
    const holdingsValue = props.holdingsValue;

    const filteredHoldings = props.holdings.filter((holding) => 
        holding.symbol === 'ALLX'
    );
    const hasMutualFunds = filteredHoldings.length > 0;

    let initialValue = 0;
    const allxSharesQuantity = filteredHoldings.reduce((accumulator, holding) => accumulator + holding.quantity, initialValue);

    const fundsTotal = hasMutualFunds ? roundToZero(allxSharesQuantity * filteredHoldings[0].price) : 0;

    const stocksValue = roundToZero(holdingsValue - fundsTotal);

    const portfolioTotal = holdingsValue + cash;

    const cashPct = cash/portfolioTotal;
    const stocksPct = stocksValue / portfolioTotal;
    const mfPct = fundsTotal / portfolioTotal;

    const displayCashPct = cashPct * 100;
    const displayStocksPct = stocksPct * 100;
    const displayMfPct = mfPct * 100;

    let labels = ['Cash'];
    let data = [twoDigitNumber(displayCashPct)];

    if(fundsTotal > 0) {
        labels.push('Mutual Funds');
        data.push(twoDigitNumber(displayMfPct));
    }

    if (stocksValue > 0) {
        labels.push('Stocks');
        data.push(twoDigitNumber(displayStocksPct));
    }

    const pieData = {
        labels: labels,
        datasets: [{
            data: data,
            backgroundColor: [
            '#013220',
            '#90ee90'
            ],
            hoverBackgroundColor: [
            '#013220',
            '#90ee90'
            ]
        }]
    };

    const options = {
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
               label: function(tooltipItem, data) {
                      return data.labels[tooltipItem.index] + ': ' + percentFormat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], true);
               }
            }
        }
    }

    const { height, width } = useWindowDimensions();

    if(width > 800) {

        return (
            <Pie data={pieData} options={options} />
        )
    } else {
        return null;
    }


}

export default PorfolioDiversificationChartOnly;