import React from 'react'

class TogglePanelButton extends React.Component {

    render() {
        if(this.props.showPanel) {
            return <button className="closePanelBtn" onClick={() => this.props.toggleShowPanel()}>Close</button>;
        } else {
            return <button className="closePanelBtn" onClick={() => this.props.toggleShowPanel()}>Portfolio</button>;
        }
    }
}

export default TogglePanelButton