import { React } from 'react'
import BankruptHolding from './BankruptHolding';
import currencyFormat from '../utilities/CurrencyFormat'

const BankruptHoldings = (props) => {

    const allLosses = props.bankruptcyLosses.losses.slice(0);
    const bankruptHoldingEls = allLosses.map((item, idx) =>
        <BankruptHolding holdingItem={item} key={idx}  />
    );

    if(allLosses.length === 0) {
        return (
            <div>
                <h2>Bankrupty Losses</h2>
                <p>You have no bankrupty losses at this time.</p>
            </div>
        )
    }
    return (
        <div>
            <h2>Bankruptcy Losses</h2>

            <h3 className="mb-0 text-left"><strong>Total Investment Loss:</strong> { currencyFormat(props.bankruptcyLosses.total, true) }</h3>
            <h3 className="mt-0 text-left"><strong>Total Market Value:</strong> { currencyFormat(props.bankruptcyLosses.valueTotal, true) }</h3>

            <table className="investments-table">
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Quantity</th>
                        <th>Cost Basis</th>
                        <th>Last Price</th>
                        <th>Investment Loss</th>
                        <th>Market Value</th>
                        <th>Bankruptcy Date</th>
                    </tr>
                </thead>
                <tbody>
                    {bankruptHoldingEls}
                </tbody>
            </table>
        </div>
    )

}

export default BankruptHoldings;