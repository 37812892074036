import twoDigitNumber from './TwoDigitNumber'

const computeStockAverage = (stocks) => {

    const stocksArr = stocks.slice(0);

    let stockAverage = 0;
    stocksArr.forEach(stock => stockAverage += stock.price);

    return twoDigitNumber(stockAverage);

}

export default computeStockAverage