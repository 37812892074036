import { React } from 'react'
import currencyFormat from './../utilities/CurrencyFormat'
import ToggleShowPerformanceLink from './ToggleShowPerformanceLink'

const JustBalance = (props) => {

    let title = props.title;

    let cssClass = '';
    if(title === 'Net Worth') {
        cssClass = 'net-worth';
    } else {
        cssClass = title.toLowerCase();
    }

    const returnCssClasses = () => {
        return 'mb-0 mt-0 table-row title-amount ' + cssClass;
    }

    const classList = returnCssClasses();

    let hideDetailsButton = title === 'Cash' ? true : false;

    let toggleDetailsButtonEl = null;
    if(!hideDetailsButton) {

        if(title === 'Net Worth') {
            toggleDetailsButtonEl = <ToggleShowPerformanceLink showDetails={props.showNetWorthChanges} toggleFunction={props.toggleShowNetWorthChanges} />
        } else if (title === 'Holdings') {
            toggleDetailsButtonEl = <ToggleShowPerformanceLink showDetails={props.showHoldingsChanges} toggleFunction={props.toggleShowHoldingsChanges} />
        }

    }




    if(!hideDetailsButton) {
        return (
            <h1 className={classList}>
                <span className="table-cell">
                    { props.title }
                </span>
                <span className="table-cell text-right pl-10">
                    {currencyFormat(props.amount, true)}
                </span>
                <span className="table-cell text-right pl-10">
                    {toggleDetailsButtonEl}
                </span>
            </h1>
        )
    } else {
        return (
            <h1 className={classList}>
                <span className="table-cell">
                    { props.title }
                </span>
                <span className="table-cell text-right pl-10">
                    {currencyFormat(props.amount, true)}
                </span>
                <span className="table-cell text-right pl-10">
                </span>
            </h1>
        )
    }

}

export default JustBalance