import React from 'react'

class ToggleMarketDiversificationButton extends React.Component {

    render() {
        if(this.props.showChart) {
            return <button onClick={() => this.props.toggleShowMarketDiversificationChart()}>Close</button>;
        } else {
            return <button onClick={() => this.props.toggleShowMarketDiversificationChart()}>Show Market Diversification</button>;
        }
    }
}

export default ToggleMarketDiversificationButton