import twoDigitNumber from './TwoDigitNumber';

const getPeriodLow = (securityObj, stocksHistoryArr, stocksArr, newStockPrice, days) => {

    let stocksHistory = stocksHistoryArr.slice(0);
    const currentStocks = stocksArr.slice(0);
    
    let minValue = Math.min(securityObj.price, newStockPrice);

    if (stocksHistory.length > days) {
        stocksHistory = stocksHistory.slice(Math.max(stocksHistory.length - days, 0));
    }

    // let cycles = 0;

    // console.log('================');
    // console.log('Stock: ' + securityObj.symbol);
    // console.log('newStockPrice: ' + newStockPrice);
    // console.log('securityObj.price: ' + securityObj.price);
    // console.log('minValue (start): ' + minValue);

    if(stocksHistory.length > 0) {
        currentStocks.forEach((stock) => {
            // cycles++;
            
            if(stock.symbol === securityObj.symbol) {
                stocksHistory.forEach((stockHistory) => {
                    // cycles++;
                    const stocksOrFundsHistory = stockHistory.stocks ? stockHistory.stocks : stockHistory.funds;

                    stocksOrFundsHistory.forEach((historyStock) => {
                        // cycles++;
                        
                        // Not the same stock OR
                        // Same stock and the price is greater than the stored minimum
                        if(stock.symbol !== historyStock.symbol || (stock.symbol === historyStock.symbol && historyStock.price > minValue)) {
                            return;
                        }
                        // console.log('historyStock.price: ' + historyStock.price);
    
                        minValue = Math.min(newStockPrice, historyStock.price);
                    })
                });
            }
        });
    }

    // console.log('52 Week Low: ' + twoDigitNumber(minValue));
    // console.log(securityObj.symbol + ' Cycles: ' + cycles);
    // console.log(' ');
    return twoDigitNumber(minValue);

}

export default getPeriodLow