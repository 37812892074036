import { React } from 'react'
import Ticker from './Ticker';
import Holdings from './Holdings';
import PauseButton from './PauseButton';
import UnpauseButton from './UnpauseButton';
import TogglePanelButton from './TogglePanelButton';
import NextDayButton from './NextDayButton';
import StockMarketTotal from './StockMarketTotal';
import BuyButton from './BuyButton';
import AddTransactionButton from './AddTransactionButton';
import SecurityChart from './SecurityChart'
import NewHeadlinesList from './NewHeadlinesList';
import StockMarketPieChart from './StockMarketPieChart'
import ToggleMarketDiversificationButton from './ToggleMarketDiversificationButton'
import JustBalance from './JustBalance'
import BalanceChange from './BalanceChange'
import PorfolioDiversificationChartOnly from './PortfolioDiversificationChartOnly'
import TogglePanelLink from './TogglePanelLink'
import currencyFormat from '../utilities/CurrencyFormat'
import NextWeekButton from './NextWeekButton'
import Modal from 'react-bootstrap/Modal'
import numberFormat from '../utilities/NumberFormat'
import percentFormat from '../utilities/PercentFormat'
import pluralizer from '../utilities/Pluralizer'
import DeleteTransactionButton from './DeleteTransactionButton'

const Game = (props) => {

    let initialValue = 0;
    const holdingsValue = props.holdings.reduce((accumulator, currentValue) => accumulator + (currentValue.price * currentValue.quantity), initialValue);

    const tickers = props.stocks;
    const stockItems = tickers.map((item, idx) =>
        <Ticker key={idx} stock={item}
            buyHolding={props.buyHolding}
            sellAllShares = { props.sellAllShares }
            balance={props.balance}
            holdings={props.holdings}
            holdingsValue={holdingsValue}
            date={props.date}
            setSelectedSecurity={props.setSelectedSecurity}
            openTransactionModal = {props.openTransactionModal}
            setTransactionModalState = {props.setTransactionModalState} />
    );

    const fundItems = props.funds.map((item, idx) =>
        <Ticker key={idx}
            stock={item}
            buyHolding={props.buyHolding}
            sellAllShares = { props.sellAllShares }
            balance={props.balance}
            holdings={props.holdings}
            holdingsValue={holdingsValue}
            date={props.date}
            setSelectedSecurity={props.setSelectedSecurity}
            openTransactionModal = {props.openTransactionModal}
            setTransactionModalState = {props.setTransactionModalState} />
    );

    const marketHeadlinesArr = props.headlines.marketHeadlines;
    const marketHeadlineItems = marketHeadlinesArr.map((item, idx) =>
        <NewHeadlinesList headline={item.headline} securities={item.securities} key={idx} setSelectedSecurity={props.setSelectedSecurity} />
    );

    const fiftyTwoWeekHeadlinesArr = props.headlines.fiftyTwoWeekHiLo;
    const fiftyTwoWeekHeadlineItems = fiftyTwoWeekHeadlinesArr.map((item, idx) =>
        <NewHeadlinesList headline={item.headline} securities={item.securities} key={idx} setSelectedSecurity={props.setSelectedSecurity} />
    );

    const otherHeadlinesArr = props.headlines.otherHeadlines;
    const otherHeadlineItems = otherHeadlinesArr.map((item, idx) =>
        <NewHeadlinesList headline={item.headline} key={idx} securities={item.securities} setSelectedSecurity={props.setSelectedSecurity} />
    );

    const otherHeadlinesTitle = otherHeadlinesArr.length > 0 ? 'OTHER HEADLINES' : '';

    const autoButton = props.paused ? <UnpauseButton unpauseGame={props.unpauseGame} /> : <PauseButton pauseGame={props.pauseGame} />;

    // Buy buttons
    const mfBuyAmtArr = [1, 5, 10, 25, 50, 100, 500, 1000];
    const mfBuyButtonsFiltered = mfBuyAmtArr.filter((amt) =>
        props.balance > props.funds[0].price * amt
    );

    const mfBuyButtons = mfBuyButtonsFiltered.map((amt, idx) => {
        return <BuyButton
                buyHolding={props.buyHolding} 
                buyQuantity={amt}
                stock={props.funds[0]}
                balance={props.balance}
                date={props.date}
                key={idx} />
    });

    const allFundArr = props.funds.filter((fund) => 
        fund.symbol === 'ALLX'
    ).slice(0);

    let goalAmount = 1000000;
    const netWorth = holdingsValue + props.balance;
    if(netWorth > goalAmount && netWorth < goalAmount * 10) {
        goalAmount = goalAmount * 10;
    } else if(netWorth > goalAmount && netWorth < goalAmount * 100) {
        goalAmount = goalAmount * 100;
    } else if (netWorth > goalAmount && netWorth < goalAmount * 1000) {
        goalAmount = goalAmount * 1000
    } else if (netWorth > goalAmount && netWorth < goalAmount * 10000) {
        goalAmount = goalAmount * 10000
    } else if (netWorth > goalAmount && netWorth < goalAmount * 100000) {
        goalAmount = goalAmount * 100000
    }


    const isStock = props.stocks.findIndex((element) => element.symbol === props.transactionSecurity.symbol) !== -1;
    const sharesOrUnits = isStock ? 'share' : 'unit';
    const maxAvailable = Math.floor(props.balance / props.transactionSecurity.price);
    let transactionTotalQuantity = 0;
    props.openTransactionList.forEach((transaction) => {
        transactionTotalQuantity += transaction.quantity
    });

    const transactionRows = props.openTransactionList.length > 1 ? props.openTransactionList.map((transaction, idx) => {
        return <div className="table-row" key={idx}>
            <div className="table-cell">{numberFormat(transaction.quantity, true)} {pluralizer(sharesOrUnits, transaction.quantity)}</div>
            <div className="table-cell text-right">{currencyFormat((transaction.quantity * transaction.security.price), true)}</div>
            <div className="table-cell pl-2">
                <DeleteTransactionButton 
                    removeTransaction={props.removeTransaction}
                    btnLabel={'Remove'}
                    index={idx}
                />
            </div>
        </div>
    }) : null;

    const transactionSummary = props.openTransactionList.length > 1 ?
        <div className="table-row transaction-summary">
            <div className="table-cell border-top">
                {numberFormat(transactionTotalQuantity, true)} {pluralizer(sharesOrUnits, transactionTotalQuantity)}
            </div>
            <div className="table-cell pl-1 text-right border-top">
                {currencyFormat(transactionTotalQuantity * props.transactionSecurity.price, true)}
            </div>
            <div className="table-cell">
                &nbsp;
            </div>
        </div>
        : <div className="table-row transaction-summary">
            <div className="table-cell">
                {numberFormat(transactionTotalQuantity, true)} {pluralizer(sharesOrUnits, transactionTotalQuantity)}
            </div>
            <div className="table-cell pl-1 text-right">
                {currencyFormat(transactionTotalQuantity * props.transactionSecurity.price, true)}
            </div>
            <div className="table-cell pl-2">
                <DeleteTransactionButton 
                        removeTransaction={props.removeTransaction}
                        btnLabel={'Remove'}
                        index={0}
                    />
            </div>
        </div>;

    const buyAmtArr = [1, 5, 10, 25, 50, 100, 500, 1000, 2500, 5000, 10000];
    const txButtonsFiltered = props.transactionSecurity ? buyAmtArr.filter((amt) =>
        props.balance - (transactionTotalQuantity * props.transactionSecurity.price) > props.transactionSecurity.price * amt
    ) : null;
    const transactionBuyButtons = txButtonsFiltered.map((amt, idx) => {
        return <AddTransactionButton
                    addTransaction={props.addTransaction} 
                    buyQuantity={amt}
                    btnLabel={numberFormat(amt, true)}
                    stock={props.transactionSecurity}
                    balance={props.balance}
                    date={props.date}
                    key={idx} />
    });

    const txHistoryObj = isStock ? props.history.stocks : props.history.funds;

    const buyButtonHTML = props.openTransactionList.length ?
        <BuyButton
            buyHolding={props.buyHolding} 
            buyQuantity={transactionTotalQuantity}
            btnLabel='Place Order'
            stock={props.transactionSecurity}
            balance={props.balance}
            date={props.date} />
        : null;

    const purchaseListWithConfirmationHTML = props.openTransactionList.length ?
        <>
            <h3 className="mt-10 mb-0 text-center">
                Order Summary
            </h3>
            <p className="mt-10 mb-10 text-center order-summary-heading">
                <strong>
                    {numberFormat(transactionTotalQuantity, true)} {pluralizer(sharesOrUnits, transactionTotalQuantity)} | {currencyFormat(transactionTotalQuantity * props.transactionSecurity.price, true)}
                </strong>
            </p>
            <p className="mb-20 text-center">Order Details</p>
            <div className="flex justify-center">
                <div className="table order-summary-table">
                        {transactionRows}
                        {transactionSummary}
                        <div className="table-row">
                            <div className="table-cell pt-20">
                                <strong>Starting Cash:</strong>
                            </div>
                            <div className="table-cell pl-1 text-right pt-20">
                                {currencyFormat(props.balance, true)}
                            </div>
                            <div className="table-cell">
                                &nbsp;
                            </div>
                        </div>
                        <div className="table-row">
                            <div className="table-cell">
                                <strong>Remaining Cash:</strong>
                            </div>
                            <div className="table-cell pl-1 text-right">
                                {currencyFormat(props.balance - (transactionTotalQuantity * props.transactionSecurity.price), true)}
                            </div>
                            <div className="table-cell">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
        </>: null;

    let enterOrderSectionHTML = null;
    if(txButtonsFiltered.length) {
        enterOrderSectionHTML = 
        <>
            <div className="text-center">
                <h3>Enter Order</h3>
            </div>
            <div className="flex-col">
                <div className="btn-group btn-group">
                    {transactionBuyButtons}
                </div>
            </div>
            <p className="text-right mt-2">
                Purchase up to {numberFormat(maxAvailable, true)} {pluralizer(sharesOrUnits, 2)} of {props.transactionSecurity.symbol}.
            </p>
        </>
    }

    return (
        <div>
            <Modal
                size="lg"
                show={props.openTransactionModal}
                onHide={() => props.setTransactionModalState(false, props.transactionSecurity)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Buy {props.transactionSecurity.symbol} {pluralizer(sharesOrUnits, 2)}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="text-left flex align-center mt-0">
                        { currencyFormat(props.transactionSecurity.price, true) }
                        <small>
                            (
                                { currencyFormat(props.transactionSecurity.movementAmt) } | { percentFormat(props.transactionSecurity.movementPct) }
                            )
                        </small>
                    </h3>
                    <div className="flex justify-center">
                        <SecurityChart
                            filterDays={365}
                            history={txHistoryObj}
                            selectedSecurity={props.transactionSecurity}
                            stocks={props.stocks}
                            funds={props.funds}
                            ticks={props.ticks}
                            isPrice={true}
                            />
                    </div>
                { enterOrderSectionHTML }
                { purchaseListWithConfirmationHTML }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-lnk" onClick={() => props.setTransactionModalState(false, props.transactionSecurity)}>
                        Close
                    </button>
                    {buyButtonHTML}
                    </Modal.Footer>
                </Modal>
            <div className="header-container">
                <div className="mm-table game-balances text-left">
                    <JustBalance
                        amount={props.balance}
                        title='Cash'
                        showNetWorthChanges={props.showNetWorthChanges}
                        showHoldingsChanges={props.showHoldingsChanges}
                        toggleShowHoldingsChanges={props.toggleShowHoldingsChanges}
                        toggleShowNetWorthChanges={props.toggleShowNetWorthChanges}
                        showPanel={props.showPanel}
                        toggleShowPanel={props.toggleShowPanel}
                    />
                    <JustBalance
                        amount={holdingsValue}
                        title='Holdings'
                        showNetWorthChanges={props.showNetWorthChanges}
                        showHoldingsChanges={props.showHoldingsChanges}
                        toggleShowHoldingsChanges={props.toggleShowHoldingsChanges}
                        toggleShowNetWorthChanges={props.toggleShowNetWorthChanges}
                        showPanel={props.showPanel}
                        toggleShowPanel={props.toggleShowPanel}
                    />
                    <BalanceChange
                        amount={holdingsValue}
                        prevBalance={ props.prevTickValues.holdings }
                        startingBalance= { 100 }
                        prevInvested= { props.prevTickValues.invested }
                        invested = { props.invested }
                        isNetWorth = { false }
                        isHoldings = { true }
                        showNetWorthChanges={props.showNetWorthChanges}
                        showHoldingsChanges={props.showHoldingsChanges}
                        showPanel={props.showPanel}
                        toggleShowPanel={props.toggleShowPanel}
                    />
                    <JustBalance
                        amount={holdingsValue + props.balance}
                        title='Net Worth'
                        showNetWorthChanges={props.showNetWorthChanges}
                        showHoldingsChanges={props.showHoldingsChanges}
                        toggleShowHoldingsChanges={props.toggleShowHoldingsChanges}
                        toggleShowNetWorthChanges={props.toggleShowNetWorthChanges}
                        showPanel={props.showPanel}
                        toggleShowPanel={props.toggleShowPanel}
                    />
                    <BalanceChange
                        amount={holdingsValue + props.balance}
                        prevBalance={ props.prevTickValues.netWorth }
                        startingBalance= {props.startingBalance + 100 }
                        prevInvested= { props.prevTickValues.invested }
                        invested = { props.invested }
                        isNetWorth = { true }
                        isHoldings = { false }
                        showNetWorthChanges={props.showNetWorthChanges}
                        showHoldingsChanges={props.showHoldingsChanges}
                        showPanel={props.showPanel}
                        toggleShowPanel={props.toggleShowPanel}
                    />
                </div>
                <div className="header-diversification-chart">
                    <div className="chart-container">
                        <PorfolioDiversificationChartOnly holdings={props.holdings} balance={props.balance} holdingsValue={holdingsValue} />
                    </div>
                    <TogglePanelLink showPanel={props.showPanel} holdings={props.holdings} balance={props.balance} holdingsValue={holdingsValue} toggleShowPanel={props.toggleShowPanel} />
                </div>
            </div>
            <div className="border-div-container border-bottom">
                <div className="left border green" style={{width: Math.min((((holdingsValue + props.balance) / goalAmount) * 100), 100)  + '%'}}></div>
            </div>

            <div className="mb-0 mt-0 pt-10 blue-bg">
                <div className="buttons-container">
                    {autoButton}
                    <NextDayButton nextTick={props.nextTick} showPanel={props.showPanel} />
                    <NextWeekButton skipDays={props.skipDays} />
                </div>
                <div className="goal-container text-right pr-5px">
                    {currencyFormat(goalAmount, true, true)}
                </div>
            </div>

            <div className="flex blue-bg pb-20 pt-20 game-section">

                <div className="flex-1 center-flex flex-col">
                    <StockMarketTotal
                        stockAverage={ props.stockAverage }
                        prevTickValues={ props.prevTickValues }
                        startingStockAverage={ props.startingStockAverage }
                        ticks= { props.ticks }
                    />
                    <SecurityChart
                        filterDays={365}
                        history={props.history.funds}
                        selectedSecurity={allFundArr[0]}
                        stocks={props.stocks}
                        funds={props.funds}
                        ticks={props.ticks}
                        title={'Market Average'}
                        isPrice={false}
                    />

                </div>
                <div className="flex-1">
                    <div>
                        <small className="game-date">{props.date.toLocaleDateString()}</small>
                        <p className="market-message mb-0 mt-0"><strong>MARKET NEWS</strong></p>
                        <p className="mt-10 mb-10">{props.headlines.topHeadline}</p>
                        <ul className="market-headlines-list mt-0 mb-10">
                            {fiftyTwoWeekHeadlineItems}
                        </ul>
                        <ul className="market-headlines-list mt-0 mb-0">
                            {marketHeadlineItems}
                        </ul>

                        <p className="market-message mb-15 mt-15"><strong>{otherHeadlinesTitle}</strong></p>
                        <ul className="market-headlines-list mt-0 mb-0">
                            {otherHeadlineItems}
                        </ul>

                    </div>
                </div>
            </div>
            <div className="flex pb-20 blue-bg border-bottom">
                <div className="flex-1 w100"> 
                    <ToggleMarketDiversificationButton showChart={props.showMarketDiversification} toggleShowMarketDiversificationChart={props.toggleShowMarketDiversificationChart} />
                    <StockMarketPieChart showChart={props.showMarketDiversification} stocks={props.stocks} />
                </div>
            </div>
            <h2 className="pb-10 mb-0 text-center">Securities</h2>
            <p className="mt-0 mb-0 text-center">Buy stocks and mutual funds (ALLX) to build your Net Worth.</p>
            <div className="flex overflow-container">
                <div className="flex-1 flex-col">
                    <table className="investments-table">
                        <thead>
                            <tr>
                                <th>Symbol</th>
                                <th><button onClick={() => props.sortStocks('price')}>Price</button></th>
                                <th><button onClick={() => props.sortStocks('movementPct')}>Today's Change</button></th>
                                <th><button onClick={() => props.sortStocks('yearlyHigh')}>52wk Hi</button></th>
                                <th><button onClick={() => props.sortStocks('yearlyLow')}>52wk Lo</button></th>
                                <th className="left-border">Quantity</th>
                                <th>Cost Basis</th>
                                <th>Value</th>
                                <th>Total Gain/Loss</th>
                                <th>Holdings</th>
                                <th className="left-border actionsCol">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fundItems}
                            {stockItems}
                        </tbody>
                    </table>
                </div>
                <Holdings holdings={props.holdings}
                          sellHolding={props.sellHolding}
                          balance={props.balance}
                          holdingsValue={holdingsValue}
                          showHoldings={ props.showHoldings }
                          toggleShowHoldings = { props.toggleShowHoldings }
                          selectedSecurity = { props.selectedSecurity }
                          history= { props.history }
                          stocks={props.stocks}
                          funds={props.funds}
                          ticks={props.ticks}
                          setSelectedSecurity={props.setSelectedSecurity}
                          stockAverage={props.stockAverage}
                          date={props.date}
                          buyHolding={props.buyHolding}
                          showPanel={props.showPanel}
                          toggleShowPanel={props.toggleShowPanel}
                          bankruptcyLosses={props.bankruptcyLosses}
                          nextTick={props.nextTick}
                          sellAllShares={props.sellAllShares}
                          prevBalance={props.prevTickValues.netWorth}
                          amount={holdingsValue + props.balance}
                          startingBalance= {props.startingBalance + 100 }
                          />
            </div>
        </div>
    )
}

export default Game