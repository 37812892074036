import { React } from 'react'
import currencyFormat from './../utilities/CurrencyFormat'
import percentFormat from './../utilities/PercentFormat'

const BalanceChange = (props) => {

    const investedDiff = props.isHoldings && props.invested !== props.prevInvested ? props.invested - props.prevInvested : 0;
    
    let todayDiffAmt = 0;
    if(props.amount !== 0)  {
        todayDiffAmt = props.isHoldings ? props.amount - props.prevBalance + (props.prevInvested - props.invested)  : props.amount - props.prevBalance - investedDiff; 
    } else {
        todayDiffAmt = 0;
    }

    // For Net worth
    const totalChangeListItem = <li className="table-row">
            <strong className="table-cell text-right">Total:</strong>
            <span className="table-cell text-right pl-10">{currencyFormat(props.amount - props.startingBalance, false)} ({percentFormat(Math.round((props.amount - props.startingBalance) / props.startingBalance * 100 * 100) / 100 , false) })</span>
            </li>;

    const netWorthList = <ul className="table-row-group pl-0 balance-change">
        <li className="table-row"><strong className="table-cell text-right">Today:</strong><span className="table-cell text-right pl-10">{currencyFormat(props.amount - props.prevBalance, false)} ({percentFormat(Math.round((props.amount - props.prevBalance) / props.prevBalance * 100 * 100) / 100, false) })</span></li>
        {totalChangeListItem}
    </ul>;

    // For Holdings

    const holdingsTotalChange = <li className="table-row"><strong className="table-cell text-right">Total:</strong><span className="table-cell text-right pl-10">{currencyFormat(props.amount - props.invested, false) } ({percentFormat(Math.round((props.amount - props.invested) / props.invested * 100 * 100) / 100, false) })</span></li>;

    const holdingsList = <ul className="table-row-group pl-0 balance-change">
        <li className="table-row"><strong className="table-cell text-right">Today:</strong><span className="table-cell text-right pl-10">{currencyFormat(todayDiffAmt, false)} ({percentFormat(Math.round(todayDiffAmt / props.prevBalance * 100 * 100) / 100, false) })</span></li>
        {holdingsTotalChange}
    </ul>

    if(props.isNetWorth && props.showNetWorthChanges) {
        return (
            netWorthList
        )
    } else if(props.isHoldings && props.showHoldingsChanges) {
        return (
            holdingsList
        )
    } else {
        return null;
    }
}

export default BalanceChange