import { React } from 'react'
import Holding from './Holding'

function HoldingsTable(props) {

    let holdings = props.holdings.slice(0);

    const filterBySecurity = Object.keys(props.selectedSecurity).length === 0 && props.selectedSecurity.constructor === Object;
    if(!filterBySecurity){
        holdings = holdings.filter((holding) => 
            holding.symbol === props.selectedSecurity.symbol
        );
    }

    const title = !filterBySecurity ? 'Tax Lots' : 'Holdings';

    const listItems = holdings.map((item, idx) =>
        <Holding key={idx} holding={item} sellHolding={props.sellHolding} setSelectedSecurity={props.setSelectedSecurity} balance={props.balance} />
    );

    if(holdings.length > 0) {
        return (
            <div>
                <h2>{title}</h2>
                <table className="investments-table">
                    <thead>
                        <tr>
                            <th>Symbol</th>
                            <th>Quantity</th>
                            <th>Purchase Price</th>
                            <th>Current Price</th>
                            <th>Total Cost</th>
                            <th>Current Value</th>
                            <th>Total Gain/Loss</th>
                            <th>Purchase Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listItems}
                    </tbody>
                </table>
            </div>
        )
    } else {
        return (
            <div>
                <h2>{title}</h2>
                <p>You have no holdings at this time.</p>
            </div>
        )
    }

}

export default HoldingsTable