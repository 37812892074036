import React from 'react'
import Modal from 'react-bootstrap/Modal'

class LaunchTransactionButton extends React.Component {

    render() {

        const container = {
            symbol: this.props.stock.symbol,
            price: this.props.stock.price,
            movementPct: 0,
            movementAmt: 0,
            quantity: 5,
            purchaseDate: this.props.date,
            purchasePrice: this.props.stock.price
        }
        
        const transactionButton = <button class="btn btn-info ml-1" onClick={() => this.props.setTransactionModalState(true, this.props.stock)}>Buy</button>;

        return transactionButton;

    }
}

export default LaunchTransactionButton