import { React } from 'react'

function SelectSecurityLink(props) {

    const newKey = Math.round(Math.random() * 1000000) + 'anb';

    return (
        <span key={newKey}><a href="/#"  onClick={(e) => {
            e.preventDefault();
            props.setSelectedSecurity(props.security);
            }
        }>{props.security.symbol}</a></span>
    )
}

export default SelectSecurityLink;