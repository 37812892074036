import React from 'react'

class ToggleShowPerformanceLink extends React.Component {

    render() {
        const showHideText = this.props.showDetails ? 'Hide' : 'See';

        return <a href="#" onClick={() => this.props.toggleFunction()} className="small-link"><small>{showHideText} Performance</small></a>;

    }
}

export default ToggleShowPerformanceLink