import { React } from 'react'
import {Line} from 'react-chartjs-2'
import currencyFormat from '../utilities/CurrencyFormat'

function PortfolioPerformanceChart(props) {

    let filterDays = 365; // set by property later props.filterDays;
    let historyArr = props.history.slice(0);

    // Remove data older than 1 year
    // if(historyArr.length > filterDays) {
    //     historyArr = historyArr.slice(Math.max(historyArr.length - filterDays, 0));
    // }

    let chartValues = [];
    let chartLabels = [];
    let data = [];

    historyArr.forEach((history) => {
        // Add the label to the labels array
        chartLabels.push(history.date.toLocaleDateString());
        // And the value to the values array
        chartValues.push(history.total);
    });


    const today = new Date();
    today.setDate(today.getDate() + props.ticks - 7);

    chartLabels.push(today.toLocaleDateString());
    chartValues.push(props.balance + props.holdingsValue);

    const title = 'Portfolio Total';
    

    // Can render price on the data label
    const options = {
        tooltips: {
            callbacks: {
               label: function(tooltipItem, data) {
                      return 'Portfolio Total: ' + currencyFormat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], true);
               }
            }
        }
    };

    data = {
        labels: chartLabels,
        datasets: [
            {
            label: title,
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: chartValues
            }
        ]
        };

    return (
        <div className="w100">
            <Line data={data} options={options} />
        </div>
    )

}

export default PortfolioPerformanceChart;