import React from 'react'
import BuyButton from './BuyButton';
import SellAllButton from './SellAllButton';
import currencyFormat from './../utilities/CurrencyFormat';
import percentFormat from './../utilities/PercentFormat';
import backgroundColor from './../utilities/BackgroundColor';
import SelectSecurityLink from './SelectSecurityLink';
import twoDigitNumber from '../utilities/TwoDigitNumber'
import LaunchTransactionButton from './LaunchTransactionButton';
import numberFormat from '../utilities/NumberFormat';

class Ticker extends React.Component {

    render() {

        const holdings = this.props.holdings;
        let resArr = [];
        const holdingsFiltered = holdings.filter((item) => {
            let i = resArr.findIndex(x => x.symbol === item.symbol);
            if(i <= -1){
                resArr.push({symbol: item.symbol, quantity: item.quantity, price: item.price, purchasePrice: item.purchasePrice });
            } else {
                resArr[i].quantity += item.quantity;
            }
            return item.symbol === this.props.stock.symbol;
        });
        
        const isHolding = (element) =>
            element.symbol === this.props.stock.symbol;

        const holdingIndex = resArr.findIndex(isHolding);

        const holdingPct = holdingIndex !== -1 ? percentFormat(resArr[holdingIndex].price * resArr[holdingIndex].quantity / this.props.holdingsValue * 100, true) : '0%';

        let initialValue = 0;
        const holdingShares = resArr.filter((holding) => {
            return holding.symbol === this.props.stock.symbol;
        });

        let holdingSharesQuantity = 0;
        let paidForHoldings = 0;
        let currentHoldingsValue = 0;
        let costBasis = 0;
        let valueChange = 0;
        let percentChange = 0;
        if(holdingIndex !== -1) {
            holdingSharesQuantity = holdingShares.reduce((accumulator, holding) => accumulator + holding.quantity, initialValue);
            paidForHoldings = twoDigitNumber(holdingsFiltered.reduce((accumulator, holding) => accumulator + (holding.quantity * holding.purchasePrice), initialValue));
            currentHoldingsValue = twoDigitNumber(holdingShares.reduce((accumulator, holding) => accumulator + (holding.quantity * this.props.stock.price), initialValue));
            costBasis = paidForHoldings / holdingSharesQuantity;
            valueChange = currencyFormat(currentHoldingsValue - paidForHoldings, false);
            percentChange = percentFormat(((currentHoldingsValue - paidForHoldings) / paidForHoldings) * 100, false);
        }

        // Buy buttons
        // const buyAmtArr = [5, 10, 25, 50, 100, 500, 1000];
        // const buyButtonsFiltered = buyAmtArr.filter((amt) =>
        //     this.props.balance > this.props.stock.price * amt
        // );
        
        // const buyButtons = buyButtonsFiltered.map((amt, idx) => {
        //     return <BuyButton
        //             buyHolding={this.props.buyHolding} 
        //             buyQuantity={amt}
        //             btnLabel={numberFormat(amt, true)}
        //             stock={this.props.stock}
        //             balance={this.props.balance}
        //             date={this.props.date}
        //             key={idx} />
        // });

        const newBuyButton = <LaunchTransactionButton
            stock={this.props.stock}
            balance={this.props.balance}
            date={this.props.date}
            openTransactionModal = {this.props.openTransactionModal}
            setTransactionModalState = {this.props.setTransactionModalState} />;

        const classList = backgroundColor(this.props.stock.movementPct);
        const returnsClassList = backgroundColor(Math.round((currentHoldingsValue - paidForHoldings) / paidForHoldings * 100));

        if(parseFloat(holdingPct) > 0) {
            return (
                <tr>
                    <td><strong><SelectSecurityLink setSelectedSecurity={this.props.setSelectedSecurity} security={this.props.stock} /></strong></td>
                    <td>{ currencyFormat(this.props.stock.price, true) }</td>
                    <td className={classList}><small>{currencyFormat(this.props.stock.movementAmt, false)} | {percentFormat(this.props.stock.movementPct, false)}</small></td>
                    <td> { currencyFormat(this.props.stock.yearlyHigh, true) }</td>
                    <td> { currencyFormat(this.props.stock.yearlyLow, true) } </td>
                    <td className="left-border">{ holdingSharesQuantity }</td>
                    <td>{ currencyFormat(costBasis, true) } </td>
                    <td><small>{ currencyFormat(currentHoldingsValue, true) }</small></td>
                    <td className={returnsClassList}><small>{ valueChange } ({ percentChange })</small></td>
                    <td>{ holdingPct }</td>
                    <td className="left-border actionsCol">
                        <SellAllButton sellAllShares={this.props.sellAllShares} stock={this.props.stock} />
                        {newBuyButton}
                    </td>
                </tr>
            )
        } else {
            return (
                <tr>
                    <td><strong><a href="/#" onClick={(e) => {
                        e.preventDefault();
                        this.props.setSelectedSecurity(this.props.stock);
                        }
                    }>{this.props.stock.symbol}</a></strong></td>
                    <td>{ currencyFormat(this.props.stock.price, true) }</td>
                    <td className={classList}><small>{currencyFormat(this.props.stock.movementAmt, false)} | {percentFormat(this.props.stock.movementPct, false)}</small></td>
                    <td> { currencyFormat(this.props.stock.yearlyHigh, true) }</td>
                    <td> { currencyFormat(this.props.stock.yearlyLow, true) }</td>
                    <td className="left-border">{ holdingSharesQuantity }</td>
                    <td>${ costBasis } </td>
                    <td><small>{ currencyFormat(currentHoldingsValue, true) }</small></td>
                    <td className={returnsClassList}><small>{ valueChange } ({ percentChange })</small></td>
                    <td>{ holdingPct }</td>
                    <td className="left-border actionsCol">
                        {newBuyButton}
                    </td>
                </tr>
            )
        }

    }
} 
 
export default Ticker