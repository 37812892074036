import { React } from 'react'
import currencyFormat from '../utilities/CurrencyFormat'
import percentFormat from '../utilities/PercentFormat'
import SecurityChart from './SecurityChart';
import {Doughnut} from 'react-chartjs-2'
import BuyButton from './BuyButton'
import SellAllButton from './SellAllButton'
import getMovingAverages from '../utilities/GetMovingAverages'
import numberFormat from '../utilities/NumberFormat'

function SelectedSecurity(props) {

    const isStock = props.stocks.findIndex((element) => element.symbol === props.selectedSecurity.symbol) !== -1;
    let historyArr = isStock ? props.history.stocks.slice(0) : props.history.funds.slice(0);
    // Remove data older than 1 year
    if(historyArr.length > 365) {
        historyArr = historyArr.slice(Math.max(historyArr.length - 365, 0));
    }

    const filteredHistoryArr = isStock ? historyArr.filter((historyObj) => {
        return historyObj.stocks.findIndex((stock) =>
        stock.symbol === props.selectedSecurity.symbol) !== -1;
    }) : null;

    const hasHistory = isStock ? filteredHistoryArr.length > 0 : historyArr.length > 0;

    const filteredHoldings = props.holdings.filter((holding) => 
        holding.symbol === props.selectedSecurity.symbol
    );
    const hasHoldings = filteredHoldings.length > 0;

    let initialValue = 0;
    const holdingSharesQuantity = filteredHoldings.reduce((accumulator, holding) => accumulator + holding.quantity, initialValue);
    const paidForHoldings = filteredHoldings.reduce((accumulator, holding) => accumulator + (holding.quantity * holding.purchasePrice), initialValue);
    const currentHoldingsValue = filteredHoldings.reduce((accumulator, holding) => accumulator + (holding.quantity * props.selectedSecurity.price), initialValue);
    const diff = currentHoldingsValue - paidForHoldings;
    const diffLabel = diff >= 0 ? 'Gain': 'Loss';
    const diffPct = ((currentHoldingsValue / paidForHoldings) - 1) * 100;

    const portfolioPct = Math.round((currentHoldingsValue / props.holdingsValue) * 10000) / 100;
    const otherPortfolioPct = Math.round((100 - portfolioPct) * 100) / 100;

    const doughnutData = {
        labels: [
            props.selectedSecurity.symbol,
            'Rest of Portfolio'
        ],
        datasets: [{
            data: [portfolioPct, otherPortfolioPct],
            backgroundColor: [
            '#013220',
            '#90ee90'
            ],
            hoverBackgroundColor: [
            '#013220',
            '#90ee90'
            ]
        }]
    };

    const options = {
        tooltips: {
            callbacks: {
               label: function(tooltipItem, data) {
                      return data.labels[tooltipItem.index] + ': ' + percentFormat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], true);
               }
            }
        }
    };


    let stockAverageDoughnutData = null;
    const stockAveragePct = Math.round((props.selectedSecurity.price/props.stockAverage) * 10000) / 100;
    const restOfStockAverage = 100 - stockAveragePct;

    if(isStock) {
        stockAverageDoughnutData = {
            labels: [
                props.selectedSecurity.symbol,
                'Rest of Stocks'
            ],
            datasets: [{
                data: [stockAveragePct, restOfStockAverage],
                backgroundColor: [
                '#013220',
                '#90ee90'
                ],
                hoverBackgroundColor: [
                '#013220',
                '#90ee90'
                ]
            }]
        };
    }

    // Get the 30, 60, and 90 day averages

    let pricesArr = [];
    for(let i = 0; i < historyArr.length; i++) {
        
        const stocksOrFunds = isStock ? 'stocks' : 'funds';
        
        historyArr[i][stocksOrFunds].forEach((stock) => {
            if(stock.symbol === props.selectedSecurity.symbol) {
                pricesArr.push(stock.price);
            }
        })
    }

    const movingAverages = getMovingAverages(pricesArr);

    // Buy buttons
    const buyAmtArr = [1, 5, 10, 25, 50, 100, 500, 1000];
    const buyButtonsFiltered = buyAmtArr.filter((amt) =>
        props.balance > props.selectedSecurity.price * amt
    );

    const buyButtons = buyButtonsFiltered.map((amt, idx) => {
        return <BuyButton
                buyHolding={props.buyHolding} 
                buyQuantity={amt}
                btnLabel={numberFormat(amt, true)}
                stock={props.selectedSecurity}
                balance={props.balance}
                date={props.date}
                key={idx} />
    });

    const unitsOrSharesText = isStock ? 'Shares' : 'Units';
    const buyButtonsEl = buyButtonsFiltered.length ? <div>
                            <h2 className="mb-0">Buy {unitsOrSharesText}</h2>
                            <div>
                                {buyButtons}
                            </div>
                        </div> : null;

    // Price comparison bar chart
    const priceRangeForYear = props.selectedSecurity.yearlyHigh - props.selectedSecurity.yearlyLow;
    const currentPriceRangeAboveLow = props.selectedSecurity.price - props.selectedSecurity.yearlyLow;
    const currentPricePercentRatio = currentPriceRangeAboveLow / priceRangeForYear;

    const priceIsYearlyLow = props.selectedSecurity.yearlyLow === props.selectedSecurity.price ? 'bold left' : 'left';
    const priceIsYearlyHigh = props.selectedSecurity.yearlyHigh === props.selectedSecurity.price ? 'bold right' : 'right';

    const priceBar = hasHistory ? <div className="price-comparison">
        <div className="fifty-two-week-price-comparison">
            <div className="left right-border" style={{width: Math.min((currentPricePercentRatio * 100), 100)  + '%'}}></div>
        </div>
        <span className={priceIsYearlyLow}>{ currencyFormat(props.selectedSecurity.yearlyLow, true) }</span>
        <span className={priceIsYearlyHigh}>{ currencyFormat(props.selectedSecurity.yearlyHigh, true) }</span>
    </div> : null;

    const selectedSecurityHeading = buyButtonsFiltered.length ?
        <div className="flex selected-security-heading space-between">
            <div className="flex-col security-price-col text-left">
                <h2 className="text-left mb-0">{ props.selectedSecurity.symbol }</h2>
                <h3 className="text-left mt-0">{ currencyFormat(props.selectedSecurity.price, true) } <small>({ currencyFormat(props.selectedSecurity.movementAmt) } | { percentFormat(props.selectedSecurity.movementPct) } )</small></h3>
                {priceBar}
            </div>
            <div className="flex-col buy-security-col">
                <h3 className="mb-0">Buy {unitsOrSharesText}</h3>
                <div>
                    {buyButtons}
                </div>
            </div>
        </div> :
        <div className="flex selected-security-heading space-between">
            <div className="flex-col security-price-col text-left">
                <h2 className="text-left mb-0">{ props.selectedSecurity.symbol }</h2>
                <h3 className="text-left mt-0">{ currencyFormat(props.selectedSecurity.price, true) } <small>({ currencyFormat(props.selectedSecurity.movementAmt) } | { percentFormat(props.selectedSecurity.movementPct) } )</small></h3>
                {priceBar}
            </div>
            <div className="flex-col buy-security-col">
            </div>
        </div>;

    if(hasHistory && hasHoldings && isStock) {
        return (
            <div className="w100 ml-15 mr-15">
                {selectedSecurityHeading}
                
                <SecurityChart
                    filterDays={365}
                    history={historyArr}
                    selectedSecurity={props.selectedSecurity}
                    stocks={props.stocks}
                    funds={props.funds}
                    ticks={props.ticks}
                    isPrice={true}
                    />

                <h2>Moving Averages</h2>

                <div className="table">
                    <div className="table-row">
                        <strong className="table-cell">
                            30-Day:
                        </strong>
                        <span className="table-cell">
                            {currencyFormat(movingAverages.thirtyDay, true)}
                        </span>
                    </div>
                    <div className="table-row">
                        <strong className="table-cell">
                            90-Day:
                        </strong>
                        <span className="table-cell">
                            {currencyFormat(movingAverages.ninetyDay, true)}
                        </span>
                    </div>
                    <div className="table-row">
                        <strong className="table-cell">
                            200-Day:
                        </strong>
                        <span className="table-cell">
                            {currencyFormat(movingAverages.twoHundredDay, true)}
                        </span>
                    </div>
                </div>

                <h2>Details</h2>

                <div className="flex">
                    <div className="flex-1">
                        <Doughnut data={stockAverageDoughnutData} options={options} />

                        <p>{percentFormat(stockAveragePct, true)} Percent of Stock Market</p>
                    </div>
                    <div className="flex-1 flex-col center-flex holdings-data">
                        <p className="text-left mt-0 mb-0"><strong>Price:</strong> { currencyFormat(props.selectedSecurity.price, true) } ({ currencyFormat(props.selectedSecurity.movementAmt) } | { percentFormat(props.selectedSecurity.movementPct) })</p>
                        <p className="text-left mb-0"><strong>52wk Hi:</strong> { currencyFormat(props.selectedSecurity.yearlyHigh, true) }</p>
                        <p className="text-left mt-0"><strong>52wk Lo:</strong> { currencyFormat(props.selectedSecurity.yearlyLow, true) }</p>                      
                    </div>
                </div>

                <h2>{ props.selectedSecurity.symbol } Holdings</h2>

                <div className="flex">
                    <div className="flex-1">
                        <Doughnut data={doughnutData} options={options} />
                        <p>{percentFormat(portfolioPct, true)} of Portfolio</p>
                    </div>
                    <div className="flex-1 flex-col center-flex holdings-data">
                        <p className="text-left mt-0 mb-0"><strong>Cost Basis:</strong> {currencyFormat((paidForHoldings / holdingSharesQuantity), true)}</p>
                        <p className="text-left mt-0 mb-15"><strong>Quantity:</strong> {holdingSharesQuantity}</p>
                        <p className="text-left mt-0 mb-0"><strong>Value:</strong> {currencyFormat(currentHoldingsValue, true)}</p>
                        <p className="text-left mt-0 mb-0"><strong>{diffLabel}:</strong> {currencyFormat(diff)} ({percentFormat(diffPct)})</p>
                        <p className="text-left mt-0 mb-15"><strong>Invested:</strong> {currencyFormat(paidForHoldings, true)}</p>
                    </div>
                </div>

                {buyButtonsEl}
                <h2 className="mb-0">Sell {unitsOrSharesText}</h2>
                <SellAllButton sellAllShares={props.sellAllShares} stock={props.selectedSecurity} />

            </div>
        )
    } else if (hasHistory && hasHoldings) {
        return (
            <div className="w100 ml-15 mr-15">

                {selectedSecurityHeading}
                
                <SecurityChart
                    filterDays={365}
                    history={historyArr}
                    selectedSecurity={props.selectedSecurity}
                    stocks={props.stocks}
                    funds={props.funds}
                    ticks={props.ticks}
                    isPrice={true}
                    />

                <p className="text-left mb-0">52wk Hi: { currencyFormat(props.selectedSecurity.yearlyHigh, true) }</p>
                <p className="text-left mt-0">52wk Lo: { currencyFormat(props.selectedSecurity.yearlyLow, true) }</p>

                <h2>{ props.selectedSecurity.symbol } Holdings</h2>

                <div className="flex">
                    <div className="flex-1">
                        <Doughnut data={doughnutData} options={options} />
                        <p>{percentFormat(portfolioPct, true)} of Portfolio</p>
                    </div>
                    <div className="flex-1 flex-col center-flex holdings-data">
                        <p className="text-left mt-0 mb-0"><strong>Cost Basis:</strong> {currencyFormat((paidForHoldings / holdingSharesQuantity), true)}</p>
                        <p className="text-left mt-0 mb-15"><strong>Quantity:</strong> {holdingSharesQuantity}</p>
                        <p className="text-left mt-0 mb-0"><strong>Value:</strong> {currencyFormat(currentHoldingsValue, true)}</p>
                        <p className="text-left mt-0 mb-0"><strong>{diffLabel}:</strong> {currencyFormat(diff)} ({percentFormat(diffPct)})</p>
                        <p className="text-left mt-0 mb-15"><strong>Invested:</strong> {currencyFormat(paidForHoldings, true)}</p>
                    </div>
                </div>

                {buyButtonsEl}
                <h2 className="mb-0">Sell {unitsOrSharesText}</h2>
                <SellAllButton sellAllShares={props.sellAllShares} stock={props.selectedSecurity} />

            </div>
        )
    } else if (hasHistory && isStock) {
        return (
            <div className="w100 ml-15 mr-15">

                {selectedSecurityHeading}

                <SecurityChart
                    filterDays={365}
                    history={historyArr}
                    selectedSecurity={props.selectedSecurity}
                    stocks={props.stocks}
                    funds={props.funds}
                    ticks={props.ticks}
                    isPrice={true}
                    />

                <h2>Moving Averages</h2>

                <div className="table">
                    <div className="table-row">
                        <strong className="table-cell">
                            30-Day:
                        </strong>
                        <span className="table-cell">
                            {currencyFormat(movingAverages.thirtyDay, true)}
                        </span>
                    </div>
                    <div className="table-row">
                        <strong className="table-cell">
                            90-Day:
                        </strong>
                        <span className="table-cell">
                            {currencyFormat(movingAverages.ninetyDay, true)}
                        </span>
                    </div>
                    <div className="table-row">
                        <strong className="table-cell">
                            200-Day:
                        </strong>
                        <span className="table-cell">
                            {currencyFormat(movingAverages.twoHundredDay, true)}
                        </span>
                    </div>
                </div>
                <h2>Details</h2>

                <div className="flex">
                    <div className="flex-1">
                        <Doughnut data={stockAverageDoughnutData} options={options} />

                        <p>{percentFormat(stockAveragePct, true)} Percent of Stock Market</p>
                    </div>
                    <div className="flex-1 flex-col center-flex holdings-data">
                        <p className="text-left mt-0 mb-0"><strong>Price:</strong> { currencyFormat(props.selectedSecurity.price, true) } ({ currencyFormat(props.selectedSecurity.movementAmt) } | { percentFormat(props.selectedSecurity.movementPct) })</p>
                        <p className="text-left mb-0"><strong>52wk Hi:</strong> { currencyFormat(props.selectedSecurity.yearlyHigh, true) }</p>
                        <p className="text-left mt-0"><strong>52wk Lo:</strong> { currencyFormat(props.selectedSecurity.yearlyLow, true) }</p>
                    </div>
                </div>

                {buyButtonsEl}

            </div>
        )
    } else if(hasHistory) {
        return (
            <div className="w100 ml-15 mr-15">

                {selectedSecurityHeading}

                <SecurityChart
                    filterDays={365}
                    history={historyArr}
                    selectedSecurity={props.selectedSecurity}
                    stocks={props.stocks}
                    funds={props.funds}
                    ticks={props.ticks}
                    isPrice={true}
                    />
    
                <p className="text-left mb-0">52wk Hi: { currencyFormat(props.selectedSecurity.yearlyHigh, true) }</p>
                <p className="text-left mt-0">52wk Lo: { currencyFormat(props.selectedSecurity.yearlyLow, true) }</p>

                <h2>Moving Averages</h2>

                <div className="table">
                    <div className="table-row">
                        <strong className="table-cell">
                            30-Day:
                        </strong>
                        <span className="table-cell">
                            {currencyFormat(movingAverages.thirtyDay, true)}
                        </span>
                    </div>
                    <div className="table-row">
                        <strong className="table-cell">
                            90-Day:
                        </strong>
                        <span className="table-cell">
                            {currencyFormat(movingAverages.ninetyDay, true)}
                        </span>
                    </div>
                    <div className="table-row">
                        <strong className="table-cell">
                            200-Day:
                        </strong>
                        <span className="table-cell">
                            {currencyFormat(movingAverages.twoHundredDay, true)}
                        </span>
                    </div>
                </div>

                {buyButtonsEl}

            </div>
        )
    } else if(hasHoldings) {
        return (
            <div className="w100 ml-15 mr-15">

                {selectedSecurityHeading}
                    
                <p className="text-left mb-0">52wk Hi: { currencyFormat(props.selectedSecurity.yearlyHigh, true) }</p>
                <p className="text-left mt-0">52wk Lo: { currencyFormat(props.selectedSecurity.yearlyLow, true) }</p>

                <h2>{ props.selectedSecurity.symbol } Holdings</h2>

                <div className="flex">
                    <div className="flex-1">
                        <Doughnut data={doughnutData} options={options} />
                        <p>{percentFormat(portfolioPct, true)} of Portfolio</p>
                    </div>
                    <div className="flex-1 flex-col center-flex holdings-data">
                        <p className="text-left mt-0 mb-0"><strong>Cost Basis:</strong> {currencyFormat((paidForHoldings / holdingSharesQuantity), true)}</p>
                        <p className="text-left mt-0 mb-15"><strong>Quantity:</strong> {holdingSharesQuantity}</p>
                        <p className="text-left mt-0 mb-0"><strong>Value:</strong> {currencyFormat(currentHoldingsValue, true)}</p>
                        <p className="text-left mt-0 mb-0"><strong>{diffLabel}:</strong> {currencyFormat(diff)} ({percentFormat(diffPct)})</p>
                        <p className="text-left mt-0 mb-15"><strong>Invested:</strong> {currencyFormat(paidForHoldings, true)}</p>
                    </div>
                </div>

                {buyButtonsEl}
                <h2 className="mb-0">Sell {unitsOrSharesText}</h2>
                <SellAllButton sellAllShares={props.sellAllShares} stock={props.selectedSecurity} />

            </div>
        )
    } else {
        return (
            <div className="w100 ml-15 mr-15">
                <h2 className="text-left mb-0">{ props.selectedSecurity.symbol }</h2>
                <h3 className="text-left mt-0">{ currencyFormat(props.selectedSecurity.price, true) } <small>({ currencyFormat(props.selectedSecurity.movementAmt) } | { percentFormat(props.selectedSecurity.movementPct) } )</small></h3>
                    
                <p className="text-left mb-0">52wk Hi: { currencyFormat(props.selectedSecurity.yearlyHigh, true) }</p>
                <p className="text-left mt-0">52wk Lo: { currencyFormat(props.selectedSecurity.yearlyLow, true) }</p>
                {buyButtonsEl}
            </div>
        ) 
    }

}

export default SelectedSecurity;