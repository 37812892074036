import React from 'react'
import portfolioRisk from '../utilities/PortfolioRisk'
import portfolioDiversification from '../utilities/PortfolioDiversification'

class TogglePanelLink extends React.Component {

    render() {
        
        const riskAssessment = portfolioDiversification(this.props.holdings, this.props.balance, this.props.holdingsValue);
        const riskProfile = portfolioRisk(riskAssessment.displayCashPct, riskAssessment.displayMfPct, riskAssessment.displayStocksPct);

        if(this.props.showPanel) {
            return <span>&nbsp;</span>;
        } else if ((this.props.balance + this.props.holdingsValue) && !this.props.showPanel) {
            return <a href="#" className="togglePortfolioLink small-link" onClick={() => this.props.toggleShowPanel()}><small>{riskProfile} Portfolio</small></a>;
        } else {
            return <a href="#" className="togglePortfolioLink small-link" onClick={() => this.props.toggleShowPanel()}><small>See Portfolio</small></a>;
        }
    }
}

export default TogglePanelLink