import { React } from 'react'
import currencyFormat from '../utilities/CurrencyFormat'
import percentFormat from '../utilities/PercentFormat'
import twoDigitNumber from '../utilities/TwoDigitNumber'
import { Pie } from 'react-chartjs-2';
import portfolioDiversification from '../utilities/PortfolioDiversification'
import portfolioRisk from '../utilities/PortfolioRisk'

function PorfolioDiversificationChart(props) {

    const riskAssessment = portfolioDiversification(props.holdings, props.balance, props.holdingsValue);
    const riskProfile = portfolioRisk(riskAssessment.displayCashPct, riskAssessment.displayMfPct, riskAssessment.displayStocksPct);

    const roundToZero = (num) => {
        if(num < 0.005) {
            return 0;
        } else {
            return num;
        }
    }

    const cash = props.balance;
    const holdingsValue = props.holdingsValue;

    const filteredHoldings = props.holdings.filter((holding) => 
        holding.symbol === 'ALLX'
    );
    const hasMutualFunds = filteredHoldings.length > 0;

    let initialValue = 0;
    const allxSharesQuantity = filteredHoldings.reduce((accumulator, holding) => accumulator + holding.quantity, initialValue);

    const fundsTotal = hasMutualFunds ? roundToZero(allxSharesQuantity * filteredHoldings[0].price) : 0;

    const stocksValue = roundToZero(holdingsValue - fundsTotal);

    const portfolioTotal = holdingsValue + cash;

    const cashPct = cash/portfolioTotal;
    const stocksPct = stocksValue / portfolioTotal;
    const mfPct = fundsTotal / portfolioTotal;

    const displayCashPct = cashPct * 100;
    const displayStocksPct = stocksPct * 100;
    const displayMfPct = mfPct * 100;

    let labels = ['Cash'];
    let data = [twoDigitNumber(riskAssessment.displayCashPct)];

    if(fundsTotal > 0) {
        labels.push('Mutual Funds');
        data.push(twoDigitNumber(riskAssessment.displayMfPct));
    }

    if (stocksValue > 0) {
        labels.push('Stocks');
        data.push(twoDigitNumber(riskAssessment.displayStocksPct));
    }

    const options = {
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
               label: function(tooltipItem, data) {
                      return data.labels[tooltipItem.index] + ': ' + percentFormat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], true);
               }
            }
        }
    }

    const pieData = {
        labels: labels,
        datasets: [{
            data: data,
            backgroundColor: [
            '#013220',
            '#90ee90'
            ],
            hoverBackgroundColor: [
            '#013220',
            '#90ee90'
            ]
        }]
    };

    const stocksRow = stocksValue ?
                        <tr>
                            <th>Stocks:</th>
                            <td>{currencyFormat(stocksValue, true)}</td>
                            <td>({percentFormat(stocksPct * 100, true)})</td>
                        </tr> :
                        null;
    
    const mutualFundsRow = fundsTotal ?
                            <tr>
                                <th>Mutual Funds:</th>
                                <td>{currencyFormat(fundsTotal, true)}</td>
                                <td>({percentFormat(mfPct * 100, true)})</td>
                            </tr> :
                            null;

    return (
        <div>
            <h2 className="portfolio-title">
                <small className="block label">Diversification</small>
                {riskProfile}
            </h2>
            <div className="flex">
                <div className="flex-col">
                    <Pie data={pieData} options={options} />
                </div>
                <div className="flex-col justify-center">

                    <table className="asset-table text-right">
                        <tbody>
                            <tr>
                                <th>Cash:</th>
                                <td>{currencyFormat(cash, true)}</td>
                                <td>({percentFormat(cashPct * 100, true)})</td>
                            </tr>{ stocksRow }{ mutualFundsRow }
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    )

}

export default PorfolioDiversificationChart;