const numberFormat = (num, noDecimal) => {
    if (num > 0 && !noDecimal) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else if (num > 0 && noDecimal) {
        return Math.round(num).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else if (num < 0) {
        num = Math.abs(num);
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
        return '0';
    }
};

export default numberFormat