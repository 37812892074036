const currencyFormat = (num, noPlus, noDecimal) => {

    const toFixedVal = noDecimal ? 0 : 2 ;

    if (num > 0) {
        const str = noPlus ? '' : '+';
        return str + '$' + num.toFixed(toFixedVal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else if (num < 0) {
        num = Math.abs(num);
        return '-$' + num.toFixed(toFixedVal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
        return '$0';
    }
};

export default currencyFormat